@import "shared/utils/colors.scss";

.magnifyContainer {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.btnCustom {
    width: auto;
    border: 1.5px solid $DARKPURPLE;
    margin-right: 5px;
    padding-left: 20px;
    padding-right: 20px;
}

.btnInsideOut {
    background: transparent !important;
    color: black;
    width: auto;
    border: 1.5px solid $DARKPURPLE;
    padding-left: 20px;
    padding-right: 20px;
}

.heading {
    color: var(--gray-gray-07, #868E96);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 20px 0px;
    /* 142.857% */
}

.btnContainer {
    display: flex;
    justify-content: end;
    margin-top: 20px;
}