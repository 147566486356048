@import "shared/utils/colors.scss";

.imageWrapper {
    width: 100px;
    height: 100px;
    margin: 3rem auto;
    margin-bottom: 1rem;
    border-radius: 100%;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    background: transparent;
}

.crossIcon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    cursor: pointer;
}

.imageWrapper img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    display: block;
    border-radius: 100%;
    text-align: center;
}

.schoolName {
    font-size: 18px;
    font-family: "poppins";
    color: $DARKPURPLE;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    display: block;
    max-width: 385px;
    line-break: anywhere;
    margin: auto
}

.addressWrapper {
    display: flex;
    gap: .5rem;
    align-items: center;
    font-size: 12px;
    color: $TEXTGREY;
    text-align: center;
    justify-content: center;

    span {
        svg {
            width: 1rem;
            height: 1rem;
        }
    }
}

.divider {
    height: 1px;
    width: 90%;
    margin: .5rem auto;
    background-color: #c9cfda;
}

.schoolInfoWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

}

.label {
    font-size: 14px;
    font-family: "poppins";
}

.value {

    padding: .5rem 0;
    border-radius: 32px;
    font-weight: 500px;
    color: black;
    font-size: 16px;
    font-family: "poppins";
    display: block;
    max-width: 250px;
    line-break: anywhere;
}

.heading {
    font-size: 18px;
    text-align: center;
    margin-bottom: 1rem;
    font-family: "poppins";
}

.username {
    font-size: 12px;
    color: $TEXTGREY;
    text-align: center;
    display: block;
    max-width: 385px;
    line-break: anywhere;
    margin: auto;
}

@media screen and (min-width:768px) {
    .schoolName {
        font-size: 24px;
    }

    .schoolInfoWrapper {
        grid-template-columns: 1fr 1fr;
    }
}