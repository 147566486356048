@import "shared/utils/colors.scss";


.dialog {
    font-family: "poppins";
}

.details {
    font-size: 13px !important;
    margin-bottom: 1.5rem !important;
    font-family: "poppins";

}

.mainHeading {
    margin: 0;
}

.heading {
    text-align: center;
    font-weight: 700 !important;
    font-size: 18px !important;
    font-family: "poppins";
}

.closeIcon {
    display: flex;
    justify-content: end;
    padding-top: 0.5rem;
    padding-right: .5rem;
}

.closeIcon svg {
    cursor: pointer;
}

.teacherCountWrapper,
.studentsCountWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: start;
}

.teacherCountWrapper {
    margin-bottom: 1rem;
}

.teacherCountWrapper p,
.studentsCountWrapper p {
    margin: 0;
}


.iconWrapper svg {
    width: 2.5rem;
    height: 2.5rem;
}



.actions {
    gap: 1rem;
    justify-content: center !important;
    margin-bottom: 1.5rem;
}

.cancelButton {
    padding: 10px 20px;
    width: max-content;
    background-color: transparent;
    border: 1px solid $DARKPURPLE;
    color: $DARKPURPLE;
}

.confirm {
    padding: 10px 20px;
    width: max-content;
    background-color: $DARKPURPLE;
    color: white;
}


@media screen and (min-width:768px) {
    .details {
        font-size: 14px !important;
    }

    .heading {
        font-size: 20px !important;
    }

    .cancelButton,
    .confirm {
        padding: 10px 40px;
    }
}