@import "shared/utils/colors.scss";

.noUserCard {
  display: flex;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  align-items: center;
}

.table {
  // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
  box-shadow: none !important;
  // margin-top: 35px;
  border-radius: 0px !important;
}

.table::-webkit-scrollbar {
  display: none;
}

.tableItem {
  color: var(--gray-gray-08, black);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.actionBtn {
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: $TEXTGREY;
    margin-right: 10px;
  }
}

.tableRow {
  td {
    min-width: 180px !important;
  }
}

.accept {
  color: white;
  background-color: $GREENBLUE;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border: none;
  padding: 5px 18px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.reject {
  color: white;
  background-color: $PINK;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border: none;
  padding: 5px 18px;
  border-radius: 5px;
  cursor: pointer;
}

.tableHeading {
  color: var(--gray-900, black);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.tableSubHeading {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.tableHead {
  th {
    border-top: 1px solid $GREY2;
    background-color: #f9fafb;
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 12px 16px;
  }
}

.gridContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // margin-right: 10px;
}

.input {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid var(--gray-gray-06, $TEXTGREY);
  border-radius: 32px;
  padding: 10px 14px;
  padding-left: 40px;
  margin-right: 10px;
}

.input:focus-visible {
  outline: none;
}

.searchIcon {
  position: absolute;
  top: 13px;
  left: 0;
  padding-left: 10px;
}

.filterContainer {
  display: flex;
  align-items: center;
}

.topHeadContainer {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 20px;
}

.topLevelContainer {
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
  border-radius: 4px;
}

.tableRow {
  td {
    min-width: 180px !important;
  }
}

.bookCover {
  width: 37.333px;
  height: 40px;
}

.statusApproved {
  background: $LIGHTGREEN;
  color: green;
  padding: 5px 10px;
  border-radius: 5px;
}

.statusRejected {
  background: $LIGHTRED;
  color: red;
  padding: 5px 10px;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  .topHeadContainer {
    display: block;
  }

  .filterContainer {
    display: block;
  }

  .input {
    width: 100%;
  }

  .gridContainer {
    margin-right: 0px;
  }

  .filterStyles {
    width: 100%;
  }

  .tableSubHeading {
    margin-bottom: 30px;
  }
}
