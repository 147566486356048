@import "shared/utils/colors.scss";

.boxWrapper {
  box-shadow: inset 0px -2px 3px 0px rgba(16, 24, 40, 0.1) !important;
  gap: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  padding-bottom: 1rem;
}

.totalItems {
  color: $DARKPURPLE;
  font-weight: bold;
}

.selectWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.selectElement {
  padding: 0.5rem 0;
}

.formControl {
  border: 1px solid #adb5bd !important;
  border-radius: 4px;
  padding: 0.2rem;
}

.formControl input {
  font-family: "poppins";
}

.formControl > div > div {
  padding-left: 0.2rem !important;
  padding-right: 20px !important;
}
