@import "shared/utils/colors.scss";

.ticketListing {
  background-color: transparent;
  height: 83vh;
  overflow: scroll;
}

.ticketListing::-webkit-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden !important;

}

.ticketListing::-webkit-scrollbar {
  width: 20px;
  height: 0;
}

.ticketListing::-webkit-scrollbar-track {
  border: 7px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #f2f2f2;
}

.ticketListing::-webkit-scrollbar-thumb {
  border: 7px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: $DARKPURPLE;
}

.heading {
  color: var(--gray-gray-09, $BLACK);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.btn {
  width: auto;
  padding: 10px 25px;
  margin-bottom: 20px;
}

.gridContain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deleteBtn {
  display: flex;
  align-items: center;
  background-color: red;
  border: none;
  color: white;
  border-radius: 32px;
  padding: 10px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 25px;

  span {
    margin-left: 5px;
  }
}

.skeleton1 {
  width: 70%;
  height: 100px !important;
  border-top-right-radius: 26px;
  border-top-left-radius: 26px;
  border-bottom-left-radius: 26px;
  margin-bottom: 20px;
}

.skeleton2 {
  width: 70%;
  height: 100px !important;
  border-top-right-radius: 26px;
  border-top-left-radius: 26px;
  border-bottom-right-radius: 26px;
}

.ticketHeading {
  display: flex;
  align-items: center;
  color: var(--gray-gray-10, $BLACK);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 10px;
  text-align: center;
  justify-content: center;
}

.btnNoData {
  width: 350px;
  margin-top: 15px;
  padding: 10px 25px;
}

.noDataContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}

.chatHead {
  padding: 20px;
  border-bottom: 1px solid $GREY2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filesContainer {
  display: flex;
  overflow-x: auto;
}

.chatContainer {
  background-color: white;
  border-radius: 6px;
  border: 1px solid $GREY2;
  height: 100%;
  // margin-top: 20px;
  // height: 82vh;
  // overflow: scroll;
}

.completeBtn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0px 25px;
  border: 1px solid var(--main-colors-primary, $DARKPURPLE);
  border-radius: 32px;
  height: 45px;
  cursor: pointer;
  color: var(--main-colors-primary, $DARKPURPLE);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  span {
    margin-left: 5px;
  }
}

.completeBtn:hover {
  background-color: $DARKPURPLE;
  color: white;

  svg {
    path {
      fill: white;
    }
  }
}

.chatHeading {
  color: var(--main-colors-primary, $DARKPURPLE);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.chatSubHeading {
  color: var(--Gray-06, $TEXTGREY);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.msgContainer {
  padding: 20px;
  position: relative;
  height: 35vh;
  overflow-y: scroll;
}

.msgContainer ::-webkit-scrollbar {
  width: 16px;
}

.msgContainer ::-webkit-scrollbar-thumb {
  background-color: red;
  border-radius: 6px;
}

.msgContainer::-webkit-scrollbar {
  display: none;
}

.sent {
  background-color: $LIGHTYELLOW;
  padding: 15px;
  width: 70%;
  border-top-right-radius: 26px;
  border-top-left-radius: 26px;
  border-bottom-left-radius: 26px;
  color: var(--gray-gray-10, $BLACK);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.16px;
  margin-bottom: 30px;
}

.received {
  background-color: $LIGHTNAVY;
  padding: 15px;
  margin-bottom: 30px;
  width: 70%;
  border-top-right-radius: 26px;
  border-top-left-radius: 26px;
  border-bottom-right-radius: 26px;
  color: var(--gray-gray-10, $BLACK);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.16px;
}

.endGrid {
  display: flex;
  justify-content: end;
}

.textArea {
  width: 100%;
  resize: none;
  height: 90px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 30px;
  color: var(--gray-gray-10, $BLACK);
  border: 1px solid $TEXTGREY;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.16px;
}

.textArea:focus-visible {
  outline: none;
}

.dateLabel {
  display: flex;
  justify-content: end;
  font-size: 12px;
}

.attachmentContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
}

.attachmentIcon {
  cursor: pointer;
}

.sendIcon {
  cursor: pointer;
}

.chatActionContainer {
  padding: 20px;
}

.fileName {
  color: var(--gray-gray-08, $BLACK);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.fileContainer {
  display: flex;
  border-radius: 6px;
  border: 1px solid $GREY2;
  padding: 10px;
  box-sizing: border-box;
  min-width: 220px;
  margin-right: 10px;
  position: relative;
  margin-bottom: 10px;
}

.fileDetail {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.06px;
}

.cancelIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 17px !important;
  color: red;
  cursor: pointer;
}

.filesContainer::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 767px) {
  .chatHead {
    display: block;
  }

  .chatTextContainer {
    justify-content: center !important;
  }

  .completeBtn {
    margin-top: 20px;
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) {
  .ticketListing {
    height: 100%;
  }
}

.fileMsg {
  padding: 15px;
  width: 200px;
  border-top-right-radius: 26px;
  border-top-left-radius: 26px;
  border-bottom-left-radius: 26px;
  color: var(--gray-gray-10, #0f1106);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.16px;
  margin-bottom: 30px;
  border-radius: 10px;
  border: 1px solid var(--gray-gray-03, #e9ecef);
  background: var(--gray-gray-01, #fff);
}