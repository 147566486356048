@import "shared/utils/colors.scss";

.card {
  min-height: 100px;
  //   height: 100% !important;
}

.gridContainer {
  margin-top: 20px !important;
  padding-left: 17px !important;
}

.cardContent {
  padding: 30px !important;
  padding-bottom: 30px !important;
  //   height: 100% !important;
}

.title {
  color: var(--gray-900, $BLACK);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  // margin-bottom: 20px;
  width: 100%;
}

.card {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
}

.leftGrid {
  padding-right: 10px !important;
  // max-height: 375px;
  margin-bottom: 2rem;
  // height: 375px !important;
}

.rightGrid {
  padding-left: 10px !important;
  // max-height: 375px;
  margin-bottom: 2rem;
  // height: 375px !important;
}

.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.noData svg {
  width: 8rem;
  height: 8rem;
}

.noData h5 {
  margin: 0;
}

@media screen and (max-width: 900px) {
  .leftGrid {
    padding-right: 0px !important;
  }

  .rightGrid {
    padding-left: 0px !important;
  }
}