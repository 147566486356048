@import "shared/utils/colors.scss";

// @import "shared/utils/colors.scss";

// .topContainer {
//     display: flex;
//     justify-content: space-between;
//     padding: 10px;
//     align-items: center;
// }

// .title {
//     margin-bottom: 0px;
//     font-size: 18px;
//     font-weight: 500;
// }

.statsWrapper {
  padding: 16px;
  margin-bottom: 2rem;
}

.crossIcon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}

.imgContainer {
  position: relative;
}

.deleteIcon {
  position: absolute;
  top: -20px;
  right: -10px;
  padding: 10px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid $BORDERGREY;
  cursor: pointer;

  path {
    stroke: $RED;
  }
}

.uploadContainer {
  border: 1px solid $BORDERGREY;
  border-radius: 16px;
  border-style: dotted;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  gap: 10px;

  label {
    color: $TEXTGREY;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    width: 70%;
    text-align: center;
  }
}

.bookCover {
  width: 37.333px;
  height: 40px;
  object-fit: cover;
  background-color: #ED447A;
;
}

.uploadedPicture {
  height: 25vh;
  border-radius: 16px;
  width: 100%;
  object-fit: contain;
  border: 1px solid $BORDERGREY;
}

// .gridContainer {
//     padding: 20px;
// }

// .singleGrid {
//     padding-right: 10px;
// }

// .card {
//     box-shadow: none !important;
//     margin-bottom: 15px !important;
// }

// .cardContent {
//     padding-bottom: 16px !important;
// }

.categoryTitle {
  color: var(--gray-900, $BLACK);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  margin: 20px 0px;
}

// .categorySubTitle {
//     color: var(--gray-900, $BLACK);
//     font-family: Poppins;
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 500;
//     line-height: 28px;
//     text-align: center;
// }

// .btnSave {
//     height: 45px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     border-radius: 5px;
//     width: auto;
//     padding: 0px 20px;
// }

// .topDiv {
//     height: 80vh;
//     overflow-y: scroll;
// }

// .topDiv::-webkit-scrollbar {
//     display: none;
// }

.btnContainer {
  display: flex;
  margin-top: 20px;
}

.btnCustom {
  width: auto;
  border: 1.5px solid $DARKPURPLE;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  margin-right: 10px;
  border-radius: 5px;
}

.btnInsideOut {
  background: transparent !important;
  color: black;
  width: auto;
  border: 1.5px solid $DARKPURPLE;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  border-radius: 5px;
}

.input {
  // margin-top: 20px;
  width: 100%;
}

.input:focus-visible {
  outline: none;
}

// @media screen and (max-width: 600px) {
//     .btnSave {
//         width: 100%;
//         margin-top: 20px;
//     }

//     .topContainer {
//         display: block;
//     }
// }

.table {
  box-shadow: none !important;
  border-radius: 0px !important;
}

.table::-webkit-scrollbar {
  display: none;
}

.tableHead {
  th {
    border-top: 1px solid $GREY2;
    background-color: #f9fafb;
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 12px 16px;
  }
}

.tableRow {
  td {
    min-width: 180px !important;
  }
}

.searchIcon {
  position: absolute;
  top: 13px;
  left: 0;
  padding-left: 10px;
}

.filterContainer {
  display: flex;
  align-items: center;
}

.input {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid var(--gray-gray-06, $TEXTGREY);
  border-radius: 32px;
  padding: 10px 14px;
  padding-left: 40px;
}

.inputCategory {
  width: 100%;
}

.inputCategory:focus-visible {
  outline: none !important;
}

.input:focus-visible {
  outline: none;
}

.actionBtn {
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: $TEXTGREY;
    margin-right: 10px;
  }
}

.topLevelContainer {
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
  border-radius: 4px;
}

.topHeadContainer {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 20px;
}

.tableHeading {
  color: var(--gray-900, black);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.tableSubHeading {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.noUserCard {
  display: flex;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  align-items: center;
}

.addBtn {
  padding-left: 20px;
  padding-right: 20px;
}

.gridContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 10px;
}

.status_active,
.status_inactive {
  padding: 0.5rem 0.5rem;
  border-radius: 5px;
}

.status_active {
  // background-color: $LIGHTGREEN2;
  color: green;
}

.status_inactive {
  // background-color: $LIGHTRED2;
  color: red;
}

@media screen and (max-width: 767px) {
  .topHeadContainer {
    display: block;
  }

  .filterContainer {
    display: block;
  }

  .input {
    width: 100%;
  }

  .gridContainer {
    margin-right: 0px;
  }

  .filterStyles {
    width: 100%;
  }

  .tableSubHeading {
    margin-bottom: 30px;
  }
}
