@import "shared/utils/colors.scss";

.backBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: none;
  font-family: "poppins";
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
}

.heading {
  font-family: "poppins";
  color: $DARKPURPLE;
}
