@import "shared/utils/colors.scss";

.container {
    padding: 2rem;
}

.imageWrapper {
    width: 20rem;
    height: 20rem;
    margin: 0;
    margin: 0 auto;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
    }
}

.closeButton {
    padding: 10px 40px !important;
    border-radius: 32px !important;
    color: white !important;
    background-color: $DARKPURPLE !important;
    text-transform: capitalize;
}

@media screen and (max-width:500px) {
    .imageWrapper {
        max-width: 15rem;
        height: 15rem;
    }
}