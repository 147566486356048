@import "shared/utils/colors.scss";

.heading {
  font-size: 24px;
  line-height: 36px;
  font-family: Poppins;
  color: black;
  margin-left: 17px;
}

.headingContainer {
  display: flex;
  align-items: center;
}

.CTAContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.toggleContainer {
  width: 152px;
  height: 45px;
  border-radius: 50px;
  border: 1px solid #e9ecef;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggleLabel {
  font-size: 14px;
  height: 33px;
  border-radius: 31px;
  padding-left: 17px;
  padding-right: 17px;
  font-family: Poppins;
  color: $BLACK;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.activeTabStyle {
  background-color: #ffe0ea !important;
  color: $PINK !important;
}

.clearAll {
  font-size: 14px;
  line-height: 21px;
  font-family: Poppins;
  color: $DARKYELLOW;
  cursor: pointer;
}

.mainContainer {
  width: 707px;
  margin: 0 auto;
  background-color: white;
  border: 1px solid #e9ecef;
  border-radius: 20px;
  padding: 14px 32px;
}

.bookImg {
  width: 64.16px;
  height: 81.2px;
  border: 5px solid #f7f7f7;
}

.notificationsContainer {
  margin-top: 20px;
}

.contentContainer {
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notificationHeading {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  font-family: Poppins;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  font-family: Poppins;
  color: #868e96;
}

.singleContentContainer {
  display: flex;
  align-items: center;
}

.singleNotificationContainer {
  display: flex;
}

.time {
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  color: $PINK;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: start;
  margin-left: 5px;
}

.singleNotificationContainer {
  padding-bottom: 20px;
  border-bottom: 1px solid #f7f7f7;
  width: 100%;
  padding-top: 20px;
}

.teacherName {
  font-family: Poppins;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;

  span {
    color: $DARKPURPLE;
  }
}

.assigneeImg {
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-right: 10px;
}

.assigneeContainer {
  display: flex;
  align-items: center;
}

.roundImg {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.grid {
  padding-top: 20px;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 20px;
}

@media screen and (max-width: 750px) {
  .mainContainer {
    width: auto;
  }
}

@media screen and (max-width: 600px) {
  // .CTAContainer {
  //   display: block !important;
  // }

  // .toggleContainer {
  //   margin: 0 auto;
  //   margin-top: 10px;
  // }

  .heading {
    font-size: 16px;
  }
  .backIcon {
    font-size: 16px !important;
  }
  .toggleContainer {
    width: fit-content;
    padding: 0px 7px;
  }
  .toggleLabel {
    font-size: 10px;
  }
  .mainContainer {
    padding: 10px 16px;
  }
}
