@import "shared/utils/colors.scss";

.headingContainer {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 4px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.heading {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: $BLACK2;
}

.subHeading {
  font-family: "Plus Jakarta Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: $GREY3;
}

.title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.description {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $TEXTGREY;
}

.detailContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}

.bookImg {
  width: 250px;
  height: 300px;
  border-radius: 20px;
  object-fit: cover;
}

.userImg {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.userLoader {
  width: 200px !important;
  height: 200px !important;
}

.nameLoader {
  width: 250px !important;
  height: 30px !important;
  margin-top: 30px !important;
}

.bookLoader {
  width: 250px !important;
  height: 300px !important;
  border-radius: 20px !important;
}

.writerImg {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
}

.writerName {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $TEXTGREY;
}

.userName {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $BLACK2;
}

.publicationName {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $TEXTGREY;
}

.cancelBtn {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: white;
  padding: 10px 44px;
  background-color: $DARKPURPLE;
  border-radius: 32px;
  border: none;
  width: 100%;
  cursor: pointer;
}

.label {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  span {
    color: $DARKPURPLE;
    font-weight: 500;
  }
}

.tabIcon {
  width: 40px;
  height: 40px;
}

.content::-webkit-scrollbar {
  display: none;
}
