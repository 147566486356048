$BACKGROUND: #fbfdff;
$BLACK: #0f1106;
$BLACK2: #18181b;
$BLACK3: #1c1c1c;
$BLACK4: #101828;
$GREY: #adb5bd;
$LIGHTGREY: #f8f8f8;
$LIGHTGREY2: #e9ecef;
$GREENBLUE: #1897a6;
$PURPLE: #fff3ff;
$DARKPURPLE: #9a469b;
$GREY2: #d9d9d9;
$GREY3: #71717a;
$GREY4: #f7f7f7;
$BORDERGREY: #e2e2e5;
$TEXTGREY: #868e96;
$TEXTGREY2: #667085;
$TEXTGREY3: #949596;
$MAINBACKGROUND: #f6f9fc;
$TransparentGREY: #f2f7fb;
$PINK: #ef437b;
$LIGHTBLUE: #e6f6fd;
$NAVY: #0d2a52;
$LIGHTGREEN: #ecfdf3;
$LIGHTRED: #fef3f2;
$HOVERGREY: #f4f4f4;
$LIGHTNAVY: #dee2e6;
$LIGHTYELLOW: #ffebc2;
$LIGHTYELLOW2: #fffaeb;
$DARKYELLOW: #fda504;
$DARK_GREEN: #1897a6;
$RED: #b42318;
$LIGHTGREEN2: #bbf7d0;
$LIGHTRED2: #fecdd3;
$LIGHT_PURPLE: #ebbbeb;
$GREEN: #027a48;
$LIGHT_BACKGROUND: #f9fafb;
$PENDING_YELLOW: #b54708;
$NEUTRAL_BLUE: #175cd3;
$LIGHTL_BLUE: #EFF8FF;
$GREEN2: #0FCF39;
$RED2: #E90505;

