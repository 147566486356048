@import "shared/utils/colors.scss";

.title {
  color: var(--gray-900, black);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.subHeading {
  width: 100% !important;
  font-size: 16px !important;
  border-radius: 0.5rem !important;
  transition: all 0.5s !important;
  text-decoration: none !important;
  display: flex !important;
  align-items: center !important;
  font-family: "Nunito" !important;
  padding-left: 0px !important;
  padding-top: 10px !important;
  font-family: Poppins !important;
}

.singleContainer {
  width: 150px;
  border: 1px solid $GREY;
  border-style: dotted;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 150px;
  cursor: pointer;
}

.loader {
  height: 153px !important;
  width: 153px !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.preview {
  width: 150px;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 150px;
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 20px;
  position: relative;
}

.imageContainer {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

.skeletonLoader {
  height: 200px;
  width: 200px;
  margin-right: 10px;
  margin-bottom: 20px;
}
