.border {
  border: 1px solid lightgrey;
}

.wrapper {
  border-bottom: 1px solid lightgrey;
  padding: 1rem 1rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.box {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 4px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}
.rectangle {
  width: 5rem;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  height: 1.5rem;
  border-radius: 4px;
}

.contentWappper {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 0.5rem 1rem;
}

.content {
  width: 100%;
  height: 1rem;
  border-radius: 4px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
