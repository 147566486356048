@import "shared/utils/colors.scss";

.ticketContainer {
  border-radius: 16px;
  background: white;
  // width: 300px;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  border: 1px solid $GREY2;
  transition: all linear 0.3s;
}

.ticketContainer:hover {
  background-color: $PINK;

  div {
    color: white;

    svg {
      path {
        fill: white;
      }
    }

    span {
      color: white;
    }
  }
}

.ticketContainer:hover .statusOpen {
  background-color: white;
  color: $PINK;
}

.active {
  background-color: $PINK;
  border-radius: 16px;
  // width: 300px;
  padding: 20px;
  cursor: pointer;
  color: white;
  margin-bottom: 20px;
  border: 1px solid $GREY2;

  div {
    color: white;

    svg {
      path {
        fill: white;
      }
    }

    span {
      color: white;
    }
  }
}

.statusOpen {
  font-weight: bold;
  background-color: $PINK;
  height: 26px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 10px;
  color: white;
  transition: all linear 0.3s;
}

.statusClose {
  font-weight: bold;
  background-color: $DARKPURPLE;
  height: 26px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 10px;
  color: white;
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.headingContainer > .ticketHeading {
  flex: 2;
}

.ticketHeading {
  display: flex;
  align-items: center;

  span {
    color: var(--gray-gray-10, $BLACK);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    // margin-left: 10px;
  }
}

.active .statusOpen {
  background-color: white;
  color: $PINK;
}

.ticketDescription {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.14px;
  margin-top: 10px;
}

.ticketReason {
  color: var(--gray-gray-08, $BLACK);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 10px;
}

.date {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.14px;
}

@media screen and (max-width: 500px) {
  .headingContainer {
    flex-direction: column;
    align-items: start;
    gap: 0.2rem;
  }
  .date {
    font-size: 12px;
  }
}