@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ps-sidebar-backdrop {
  opacity: 0;
}

@font-face {
  font-family: "Urdu";
  src: url("../src/assets/fonts/Jameel\ Noori\ Nastaleeq\ Kasheeda.ttf");
}

.react-datepicker__time-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--selected{
  background-color: #9a469b !important;
}

td {
  color: var(--gray-gray-08, black) !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 20px !important;
}

@media screen and (max-width: 767px) {
  td {
    min-width: 160px !important;
  }
}

aside > div::-webkit-scrollbar {
  display: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__day--selected {
  background-color: #9a469b;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day:hover {
  background-color: #d59cd5;
}

.react-datepicker__current-month {
  font-family: Poppins;
}

.react-datepicker__header {
  background-color: transparent;
  border-bottom: none;
}

.react-datepicker__day-names {
  margin-top: 15px;
}

.react-datepicker-popper {
  position: fixed !important;
}

.react-datepicker__day--in-range{
  background-color: #9A469B;
}

.ReactCrop {
  max-height: 300px;
  width: 100%;
}
#cropperInput {
  background: linear-gradient(
    to right,
    #ef437b 0%,
    #f0f3f6 0%,
    #f0f3f6 50%,
    #f0f3f6 100%
  );
  border: none;
  border-radius: 8px;
  height: 3px;
  padding: 0px !important;
  width: 273px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

#cropperInput::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ef437b;
  cursor: pointer;
  border: 2px solid white;
}

.reactEasyCrop_Container {
  min-height: 274px !important;
  width: 100% !important;
  max-height: 274px !important;
  position: relative !important;
}
.reactEasyCrop_CropArea {
  /* width: 357.05px !important;
  height: 140.05px !important; */
}
.cropper {
  width: 100%;
}

#mySlider {
  background: linear-gradient(
    to right,
    #ef437b 0%,
    #d9e0e9 0%,
    #d9e0e9 50%,
    #d9e0e9 100%
  );
  border: none;
  border-radius: 8px;
  height: 9px;
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
  padding: 0px;
}
#mySlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  cursor: grab;
  border: 2px solid #ef437b;
}



.notificationScheduleBtnContainer{
  .react-datepicker-wrapper {
    width: fit-content !important;
    .react-datepicker__input-container {
      width: fit-content;
    }
  }
}



.tooltip {
  background-color: #101828 !important;
  padding: 8px 12px !important;
  font-family: Inter !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 600 !important;
}

.arrow{
  color:#101828 !important;
}