@import "shared/utils/colors.scss";

.mainContainer {
  border-radius: 16px;
  background-color: $MAINBACKGROUND;
  padding: 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.input {
  width: 100%;
  border-radius: 16px;
  border: 1px solid $BORDERGREY;
}

.textEditorContainer > div:first-child > div:first-child {
  border: 1.4px solid $BORDERGREY;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: white;
}

.textEditorContainer > div:first-child > div:last-child {
  border: 1.4px solid $BORDERGREY;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  height: 400px;
  background-color: white;

  &::-webkit-scrollbar-track {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #f7a1bd;
  }

  scrollbar-color: #f7a1bd transparent !important;
  scrollbar-width: thin !important;
}
