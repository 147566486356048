@import "shared/utils/colors.scss";

.crossIcon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}

.content {
  position: relative;
  padding: 20px !important;
  flex-direction: column;
}

.contentLabel {
  color: var(--gray-gray-10, $BLACK);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  margin: 15px 0px;
}

.flexGrid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noBtn {
  background-color: white;
  border: none;
  border: 1.5px solid $DARKPURPLE;
  padding: 10px;
  width: 100%;
  border-radius: 28px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 10px;
  color: black;
}

.buttonsContainer {
  width: 70%;
  display: flex;
}

.contentLabel {
  margin-bottom: 1.5rem;
  line-height: 2rem;
  font-size: 17px;
  text-align: start;
}

.contentLabel span {
  color: #9a469b;
}

.textAreaWrapper {
  width: 100%;
  //   max-width: 90%;
  //   width: 90%;
  //   margin: auto;
}

.textAreaWrapper {
  .formGroup {
    font-family: Poppins;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 1rem 0;
    gap: 0.5rem;
    margin-top: 0;
  }
}
.formGroup label {
  font-weight: bold;
}

.formGroup {
  textarea {
    width: -webkit-fill-available;
    padding: 0.5rem;
    color: gray;
    font-family: poppins;
    height: 5rem;
    resize: none;
  }
}

::-webkit-scrollbar-track {
  background-color: #9a469b;
}

::-webkit-scrollbar-thumb {
  background-color: #9a469b;
}

@media screen and (min-width: 500px) {
  .textAreaWrapper {
    // max-width: 420px;
    // margin: auto;
  }
  .contentLabel {
    font-size: 20px;
  }
}

@media screen and (max-width: 767px) {
  .contentLabel {
    margin-top: 35px;
  }
}

@media screen and (max-width: 600px) {
  .buttonsContainer {
    display: block;
    width: 100%;
  }

  .noBtn {
    margin-left: 0px;
    margin-top: 10px;
  }

  .contentLabel {
    line-height: 30px;
    margin-top: 40px;
  }
}
