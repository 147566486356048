@import "shared/utils/colors.scss";

.card {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
}

.cardContent {
    padding: 24px !important;
}

.selectionContainer {
    padding: 0px 20px;
    margin-top: 20px !important;
}

.reviewDisbaled{
    color: $TEXTGREY !important;
}

.checkboxDisabled{
    color: $LIGHT_PURPLE !important;

}

.checkbox {
    color: $DARKPURPLE !important;
}

.title {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.btnContainer {
    margin: 20px 0px;
    display: flex;
    justify-content: end;
}

.btnCustom {
    width: auto;
    border: 1.5px solid $DARKPURPLE;
    margin-left: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.btnInsideOut {
    background: transparent !important;
    color: black;
    width: auto;
    border: 1.5px solid $DARKPURPLE;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15px;

}


.btn {
    width: 80%;
    height: 35px;
    background: none;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px 0px 15px;
}

.textarea {
    border: 1.5px solid $TEXTGREY;
    border-radius: 5px;
    resize: none;
    width: 80%;
    padding: 6px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: Poppins;
    height: 80px;
}

.textarea:focus-visible {
    outline: none;
}

.radio {
    margin-right: 20px;

    label {
        margin-right: 0px;

        span {
            color: $DARKPURPLE !important;
        }
    }
}

.checkBoxContainer {
    max-height: 300px;
    overflow-y: auto;
    margin-right: 20px;
}

.checkBoxContainer:not(:hover)::-webkit-scrollbar {
    width: 0;
}


.checkBoxContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.checkBoxContainer::-webkit-scrollbar {
    width: 4px;
    /* Adjust the width as needed */
    background-color: #F5F5F5;
}

.checkBoxContainer::-webkit-scrollbar-thumb {
    background-color: $DARKPURPLE;
}


.table {
    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
    box-shadow: none !important;
    // margin-top: 35px;
    border-radius: 0px !important;

}

.tableItem {
    color: var(--gray-gray-08, black);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
}

.actionBtn {
    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: $TEXTGREY;
        margin-right: 10px;
    }
}

.tableHeading {
    color: var(--gray-900, black);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.tableSubHeading {
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.tableHead {
    th {
        border-top: 1px solid $GREY2;
        background-color: #F9FAFB;
        color: var(--gray-gray-07, $TEXTGREY);
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        padding: 12px 16px;

    }
}

.titleTable {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.btnCustom {
    padding-left: 20px;
    padding-right: 20px;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;

}

@media screen and (max-width:600px) {
    .btn {
        width: 100%;
    }

    .textarea {
        width: 100%;
    }
}

@media screen and (max-width:750px) {
    .btnCustom {
        width: 100%;
        margin-bottom: 20px;
        margin-left: 0px;
    }

    .btnInsideOut {
        width: 100%;
        margin-bottom: 20px;
        margin-left: 0px;
    }

    .btnContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}