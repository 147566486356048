@import "../../shared/utils/colors.scss";


.box {
    background-color: $BACKGROUND;
    height: 100vh;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.cardContainer {
    width: 660px;
    height: 500px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 28px !important;
    border: 1px solid var(--gray-gray-06, $GREY);
    background: white;
    padding: 30px 0px;
}

.logo {
    width: 133px;
    height: 87.659px;
    padding-bottom: 32px;
}

.label {
    color: var(--gray-gray-10, $BLACK);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 10px;
    display: flex;
    justify-content: start;
}

.gridContainer {
    display: flex;
    flex-direction: column;
    width: 400px;
}

.customInput {
    height: 30px;
    border-radius: 50px;
    padding: 25px 15px;
    border: 1.5px solid $GREY;
    color: var(--gray-gray-09, $BLACK);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    box-sizing: border-box;
}

.customInput:focus-visible {
    outline: none;
}

.customerContainer {
    display: flex;
    justify-content: space-between;
    width: 400px;
}

.checkbox {
    color: $GREENBLUE !important;
}

.button {
    width: 400px;
    height: 50px;
    border: none;
    background: #9A469B;
    border-radius: 32px;
    color: white;
    color: var(--gray-gray-01, #FFF);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 30px;
    cursor: pointer;
}

.errorText {
    height: 20px;
    font-size: 12px;
    color: red;
    display: flex;
    justify-content: start;
}

.labelHead {
    color: var(--gray-gray-07, $GREY);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.gridCenter {
    display: flex;
    justify-content: center;
}

.forgetLabel {
    color: var(--main-colors-dark-green, $GREENBLUE);
    text-align: right;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.icon {
    color: $GREY;
    position: absolute;
    top: 13px;
    right:13px;
    cursor: pointer;
}

@media screen and (max-width:767px) {
    .cardContainer {
        border: none;
        box-shadow: none !important;
    }

    .form {
        width: 90%;
    }

    .gridContainer,
    .customerContainer,
    .button {
        width: 100%;
    }


}