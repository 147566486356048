@import "shared/utils/colors.scss";

.heading {
  font-family: Poppins;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: $BLACK2;
}

.crossIcon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}

.bannerImg {
  width: 100%;
  height: 200px;
  border-radius: 16px;
  object-fit: cover;
}

.sectionHeading {
  font-family: Poppins;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: $TEXTGREY;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 1rem;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.labelDescription {
  font-family: Poppins;
  font-weight: 500;
  font-size: 21px;
  line-height: 28px;
  color: $BLACK2;

  p {
    margin: 0px;
    word-break: break-all;

    img {
      height: 100%;
      width: 100%;
    }
  }

  img {
    height: 100%;
    width: 100%;
  }
}

.dialogContainer {
  &::-webkit-scrollbar {
    display: none !important;
  }

  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.scrollHide {
  &::-webkit-scrollbar {
    display: none !important;
  }

  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.buttonImage {
  width: 400px;
  height: 100px;
  object-fit: contain;
}
