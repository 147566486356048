@import "shared/utils/colors.scss";

.table {
    box-shadow: none !important;
    border-radius: 0px !important;
    margin-top: 20px;
}

.crossIcon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
}

.title {
    color: var(--gray-900, black);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.bookImage {
    height: 230px;
    width: 100%;
    object-fit: contain;
}

.bookContainer {
    display: flex;
}

.bookDetail {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div {
        font-family: Poppins;
    }
}

.gridContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 10px;
}


.input {
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border: 1px solid var(--gray-gray-06, $TEXTGREY);
    border-radius: 32px;
    padding: 10px 14px;
    padding-left: 40px;
}

.input:focus-visible {
    outline: none;
}

.searchIcon {
    position: absolute;
    top: 15px;
    left: 0;
    padding-left: 15px;
}

.bookAssignSection {
    display: flex;
    box-sizing: border-box;
}


.heading {
    color: var(--gray-900, black);
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.subHeading {
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.topleveContainer {
    // border: 1px solid $GREY2;
    padding: 20px;
    // margin-top: 40px;
    border-radius: 13px;
}

.tableRowStyle {
    td {
        padding: 10px 0px;
    }
}

.profilePic {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.dialog {
    padding: 50px !important;
}

.assignBtn {
    width: 150px;
    padding: 18px;
    background: $DARKPURPLE;
    border: none;
    border-radius: 32px;
    color: white;
    cursor: pointer;

}

.boxContainer {
    display: flex !important;
    justify-content: space-between;
    padding: 10px 0px;
    border-bottom: 1px solid $GREY2
}

.singleContainer {
    display: flex !important;
    align-items: center !important;
}

.genres {
    color: black;
    margin-right: 5px;
    font-size: 13px;
    font-weight: 500;
}



@media screen and (max-width:800px) {


    .searchIcon {
        top: 23px;
    }
}

@media screen and (max-width:650px) {
    .bookAssignSection {
        display: block;
    }

    .bookContainer {
        display: block;
    }

    .gridContainer {
        margin-top: 20px;
    }

    .searchIcon {
        top: 12px;
        left: 2px;
    }

    .dialog {
        padding: 25px !important;
    }

    .boxContainer {
        display: block !important;
    }

    .assignBtn {
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
    }
}