@import "shared/utils/colors.scss";

.crossIcon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
}

.table {
    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
    box-shadow: none !important;
    // margin-top: 35px;
    border-radius: 0px !important;

}

.mainTitle {
    color: var(--gray-gray-10, $BLACK);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.tableHead {
    th {
        border-top: 1px solid $GREY2;
        background-color: #F9FAFB;
        color: var(--gray-gray-07, $TEXTGREY);
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        padding: 12px 16px;

    }
}

.dot {
    color: $PINK;
    font-size: 40px;
    margin-right: 5px;
}


.title {
    color: var(--gray-gray-10, $BLACK);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.time {
    color: var(--gray-gray-08, $BLACK);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}



.revisionDescription {
    color: var(--gray-gray-08, $BLACK);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}


.topLevelContainer {
    padding: 10px;
    border-bottom: 1.5px solid $GREY2;
}

.headingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.replySection {
    margin-top: 20px;
    border: 1px solid $TEXTGREY;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.replyText {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--gray-gray-08, $BLACK);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.end {
    display: flex;
    justify-content: end;
}

.repliedText {
    color: var(--gray-gray-08, $BLACK);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background-color: $LIGHTYELLOW2;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
}

.textarea {
    width: 100%;
    border-radius: 6px;
    resize: none;
    border: 1.5px solid grey;
    padding: 10px;
    box-sizing: border-box;
    height: 100px;
}

textarea:focus-visible {
    outline: none;
}

.sendIcon {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.tabs {
    font-family: Poppins !important;
    font-feature-settings: 'clig'off, 'liga'off !important;
    font-family: Poppins !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
}

.tabContainer {
    div {
        div {
            justify-content: center !important;
        }
    }
}

.statsContainer {
    padding: 20px;
    display: flex;

    div {
        margin-right: 20px;
    }
}

.subTitle {
    color: var(--gray-gray-10, $BLACK);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
}

.revisionContainer {
    padding: 15px;
    border: 1px solid;
    border-radius: 9px;
    margin-bottom: 20px;
}

.innerResponse {
    padding: 10px;
    border-radius: 10px;
    background-color: $LIGHTYELLOW2;


}

@media screen and (max-width: 600px) {
    .statsContainer {
        display: block;
    }
}