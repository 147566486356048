.loaderContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0006;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    z-index: 999999;
  }
  