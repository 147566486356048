@import "shared/utils/colors.scss";

.mouMenu {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
}

.mouEditorContainer {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
  padding: 25px;
}

.noDataCard {
  display: flex;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  align-items: center;
}

.signatureContainer {
  display: flex;
  align-items: end;
  margin: 20px 0px;
  justify-content: space-between;
}

.signatureImg {
  width: 154px;
  height: 65.904px;
}

.line {
  // style={{
  //     width: "100%",
  //     border: "1px solid black",
  //     height: "0.5px",
  //   }}
  width: 200px;
  border: 1px solid $DARKPURPLE;
  height: 0.1px;
  margin: 15px 0px;
}

.card {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  justify-content: space-between;
  cursor: pointer;
  background-color: $PURPLE !important;
  color: $DARKPURPLE !important;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 0px !important;
}

.mouTopLevelContainer {
  max-height: 300px;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

.mouTopLevelContainer::-webkit-scrollbar {
  --scrollbar-thumb-color: red;
  --scrollbar-track-color: red;
  overflow-x: none;
  width: 2px;
}

.mouTopLevelContainer::-webkit-scrollbar-thumb {
  background-color: $DARKPURPLE;
}

.mouTopLevelContainer::-webkit-scrollbar-track {
  background-color: transparent;
}

.mouContainer {
  padding: 10px;
  background-color: white;
  cursor: pointer;
}

.mouContainer:hover {
  background-color: $HOVERGREY;
}

.mouHeading {
  color: var(--gray-gray-08, black);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mouDate {
  color: var(--Gray-06, $TEXTGREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mouInnerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.mouLabel {
  color: var(--main-colors-primary, $DARKPURPLE);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mouHeading {
  color: var(--gray-gray-08, $BLACK);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  span {
    color: $DARKPURPLE;
  }
}

.textarea {
  width: 100%;
  height: 250px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid var(--gray-gray-06, $GREY);
  border-radius: 20px;
  resize: none;
  margin-top: 10px;
  font-family: Poppins;
}

.textarea:focus-visible {
  outline: none;
}

.inputContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;

  div {
    color: var(--gray-gray-08, $BLACK);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    span {
      color: $DARKPURPLE;
    }

    input {
      width: 100%;
    }
  }
}

.input:focus-visible {
  outline: none;
}

.btn {
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 10px;
}

.btnInside {
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 10px;
  background-color: white;
  border: 2px solid $DARKPURPLE;
  color: black;
}

.singleMOU {
  border-radius: 16px;
  background: white;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  border: 1px solid $GREY2;
}

.agreement {
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

.pending {
  display: flex;
  padding: 4px 14px;
  align-items: center;
  border-radius: 28px;
  background: var(--main-colors-primary, $GREENBLUE);
  width: fit-content;
  color: white;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.agreed {
  display: flex;
  padding: 4px 14px;
  align-items: center;
  border-radius: 28px;
  background: var(--main-colors-primary, $PINK);
  width: fit-content;
  color: white;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.declined {
  display: flex;
  padding: 4px 14px;
  align-items: center;
  border-radius: 28px;
  background: var(--main-colors-primary, #9a469b);
  width: fit-content;
  color: white;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.singleMOU:hover {
  background-color: $PINK;
  color: white;

  .mouHeading,
  .mouDate {
    color: white;
  }

  .pending,
  .declined,
  .agreed {
    background: white;
    color: $PINK;
  }
}

.activeMOU {
  background-color: $PINK;
  color: white;

  .mouHeading,
  .mouDate {
    color: white;
  }

  .pending,
  .declined,
  .agreed {
    background: white;
    color: $PINK;
  }

  background-color: #ef437b;
  color: white;
  border-radius: 16px;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  border: 1px solid #d9d9d9;
}

.titleHead {
  color: var(--main-colors-primary, $DARKPURPLE);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.errorText {
  height: 20px;
  font-size: 12px;
  color: red;
  display: flex;
  justify-content: start;
}

@media screen and (min-width: 990px) and (max-width: 1140px) {
  .singleMOUWrapper {
    flex-direction: column;
    row-gap: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .agreement {
    width: 100% !important;
    margin-top: 0.5rem;
  }
}
