@import "shared/utils/colors.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.containerheading {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: $BLACK2;
}

.statsContainer {
  border-radius: 12px;
  background-color: white !important;
  border: 1px solid $BORDERGREY;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.singleStatsCard {
  background-color: $MAINBACKGROUND;
  border: 1px solid $LIGHTGREY2;
  border-radius: 8px !important;
  padding: 20px 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
  position: relative;
}

.heading {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.label {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $BLACK4;
}

.statsCount {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: $BLACK4;
}

.unchecked {
  border: 1.5px solid $GREY;
  border-radius: 4px;
  width: fit-content;
  cursor: pointer;
  width: 13px;
  height: 13px;
  border-radius: 50% !important;
}

.checked {
  border: 1.5px solid transparent;
  border-radius: 4px;
  width: fit-content;
  cursor: pointer;
  background-color: $DARKPURPLE;
  border-radius: 50% !important;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;

  svg {
    width: 11px;
    height: 11px;
  }
}

.typeContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.sectionContainer {
  display: flex;
  justify-content: center;
  gap: 32px;
  flex-direction: column;
  height: 100%;
}

.mapContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: $MAINBACKGROUND;
  border: 1px solid $LIGHTGREY2;
  padding: 20px 12px;
  border-radius: 8px !important;
  height: 100%;
}

.notificationTitle {
  padding-left: 10px;
  width: 100%;
}

.searchInput {
  padding-left: 40px;
  width: 100%;
}

.searchIcon {
  position: absolute;
  top: 12px;
  left: 12px;
}

.inputMainContainer {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.textarea {
  resize: none;
  height: 150px;
  width: 100%;
  border-radius: 32px;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid var(--gray-gray-06, #adb5bd);
  font-family: "Poppins";
  font-weight: 400;
  line-height: 16px;
  color: $BLACK2;
}

.sendNotificationBtn {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none !important;
  cursor: pointer;
  background-color: $DARKPURPLE;
  border-radius: 32px;
  color: white;
  padding: 10px 60px;
  margin-left: 10px;
  min-width: 260px;
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: end;
  // gap: 10px;
}

.iconStyle {
  width: 60px;
  height: 60px;
}

.error {
  color: $RED;
}

.limitLabel {
  width: 100%;
  box-sizing: border-box;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 16px;
  color: #18181b;
  margin-top: 10px;
  font-size: 12px;
  display: flex;
  justify-content: end;
}

.disabledCheckbox {
  cursor: auto !important;
}

.disabledOption {
  color: $TEXTGREY3 !important;
}

.textareaLabelContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.bookPic {
  width: 70px;
  height: 100px;
  object-fit: contain;
  border-radius: 10px;
}

.profile_picture {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.profile_picture_selected {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.menuTitle {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #101828;
}

.username {
  color: $TEXTGREY;
  font-size: 12px;
  line-height: 16px;
}

.scheduleBtn {
  // font-family: "Poppins";
  // font-weight: 500;
  // font-size: 16px;
  // line-height: 24px;
  // cursor: pointer;

  // border: 1px solid $DARKPURPLE;
  // background-color: white !important;
  // border-radius: 32px;
  // color: $DARKPURPLE;
  // padding: 10px 44px;
  // width: fit-content;

  background-color: white !important;
  border-radius: 32px;
  border: 1px solid var(--gray-gray-06, #adb5bd);
  padding: 10px 60px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}

// .menuItemContainer {
//   display: flex;
//   align-items: center;
//   padding: 10px;
// }

.radioCheckBox {
  color: $DARKPURPLE !important;
}

.scheduleContainer {
  display: flex;
  gap: 10px;
  width: fit-content;
  margin-top: 10px;
}

//autocomplete styling

.selectedNameAutoComplete {
  width: 100%;
  box-sizing: border-box;
  height: 45px;
  border-radius: 32px;
  border: 1px solid var(--gray-gray-06, #adb5bd);
  color: #0f1106;
  padding: 10px !important;
  font-family: Poppins;
  font-weight: 400;
  font-size: 13px !important;
}

.displayAlign {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  border: none !important;

  div {
    padding: 0px !important;

    input {
      width: 100%;
      box-sizing: border-box;
      height: 45px;
      border-radius: 32px;
      border: 1px solid var(--gray-gray-06, #adb5bd);
      color: #0f1106;
      padding: 10px !important;
      font-family: Poppins;
      font-weight: 400;
      font-size: 13px !important;
    }

    fieldset {
      border: none !important;
    }
  }
}

@media screen and (max-width: 990px) {
  .sendNotificationBtn {
    min-width: 100%;
  }
}
