@import "shared/utils/colors.scss";

.topLevelContainer {
  max-width: 100% !important;
  margin: 0;
}

.headerMainContainer {
  background: transparent !important;
  box-shadow: -16px -20px 4px -1px rgba(0, 0, 0, 0.2),
    -4px -2px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;

}

.nameLabel {
  color: var(--gray-gray-10, black);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
}

.emailLabel {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
}

.flexGrid {
  display: flex;
  align-items: center;
}

.buttonInfo {
  border-radius: 5px !important;
  padding: 3px !important;
}

.headerItems {
  display: flex !important;
  justify-content: end !important;
}

.booksSearch {
  display: flex;
  position: relative;
  // gap: 8px;
  border: 1px solid #ADB5BD;
  border-radius: 32px;
  align-items: center;
  padding: 0 8px;

  // max-width: 15rem;
  // width: 15rem;
  svg {
    color: #868E96
  }

  input {
    border: none;
    height: auto;
  }

  input::placeholder {
    font-size: 10px;
  }

  // svg {
  //   color: black;
  // }

  .seacrhResultsContainer {
    font-family: 'poppins';
    position: absolute;
    background-color: white;
    border-radius: 5px;
    padding: 1rem;
    top: 103%;
    width: 100%;
    z-index: 99;
    right: 0;
    max-height: 15rem;
    min-width: 250px;
    // height: 15rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;

    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    .noBookFound {
      div {
        padding: 1rem 0;

        svg {
          width: 7rem;
          height: 7rem;
        }
      }
    }
  }
}

@media screen and (max-width:500px) {
  .seacrhResultsContainer {
    right: -50% !important;
  }
}


@media screen and (min-width:993px) {
  .displayNone {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .displayNone {
    position: absolute;
  }
}

@media screen and (max-width: 800px) {
  .flexGrid {
    display: none;
  }
}