@import "shared/utils/colors.scss";

.otpContainer {
    div {
        input {
            width: 68px !important;
            background-color: #F2F7FB;
            color: var(--light-black-black-100, #0F1106);
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            border-radius: 12px;
            margin-left: 20px;
            height: 60px;
            border: none;
        }

        input:focus-visible {
            outline: none;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }


    }

    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.title {
    color: var(--gray-gray-10, $BLACK);
    text-align: center;
    font-family: Poppins;
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    margin-top: 20px !important;
    margin-bottom: 0px !important;
}

.subTitle {
    color: var(--gray-gray-07, $TEXTGREY);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 0px !important;
}

.heading {
    color: var(--gray-gray-07, $BLACK);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0px !important;
    margin-bottom: 10px !important;

    span {
        color: $DARKPURPLE !important;
        margin-left: 5px;
    }

}

.dialogContent {
    padding: 35px !important;
}

.btnContainer {
    // margin: 20px 0px;
    display: flex;
    justify-content: end;
    margin-top: 60px;
}

.btnCustom {
    width: 100%;
    border: 1.5px solid $DARKPURPLE;
    margin-right: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.btnInsideOut {
    background: transparent !important;
    color: black !important;
    width: auto !important;
    border: 1.5px solid $DARKPURPLE !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    width: 100% !important;
}

// .crossIcon {
//     position: absolute !important;
//     top: 35px;
//     right: 25px;
//     min-width: auto !important;
// }

.crossIcon {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: end;
}

.prevBtn {
    position: absolute !important;
    top: 35px;
    left: 25px;
    min-width: auto !important;
}

.input:focus-visible {
    outline: none;
}


.insideContainer {
    width: 85%;
}

.errorText {
    height: 30px;
    font-size: 12px;
    color: red;
    display: flex;
    justify-content: start;
}

.iconContainer {
    position: absolute;
    top: 11px;
    right: 29px;
    color: $TEXTGREY;
    cursor: pointer;
}

@media screen and (max-width: 580px) {
    .insideContainer {
        width: 100% !important;
    }

    .dialogContent {
        padding: 24px !important;
    }

    .otpContainer {
        div {
            input {
                margin-left: 4px !important;
            }
        }
    }

    .title {
        margin-top: 60px !important;
        font-size: 21px;
    }
}