@import "shared/utils/colors.scss";

.table {
  box-shadow: none !important;
  border-radius: 0px !important;
}

.table::-webkit-scrollbar {
  display: none;
}

.tableHead {
  th {
    border-top: 1px solid $GREY2;
    background-color: #f9fafb;
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 12px 16px;
  }
}

.tableRow {
  td {
    min-width: 180px !important;
  }
}

.container {
  box-shadow: none !important;

  &::-webkit-scrollbar-track {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #f7a1bd;
  }
}

.topLevelContainer {
  background-color: white;
  // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
  //   0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
  border-radius: 16px;
  margin-top: 2rem;
  border: 1px solid $BORDERGREY;
}

.topHeadContainer {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 24px 20px;
}

.tableHeading {
  color: var(--gray-900, black);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.tableSubHeading {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.filterContainer {
  display: flex;
  align-items: center;
}

.gridContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 10px;
}

.searchIcon {
  position: absolute;
  top: 13px;
  left: 0;
  padding-left: 10px;
}

.input {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid var(--gray-gray-06, $TEXTGREY);
  border-radius: 32px;
  padding: 10px 14px;
  padding-left: 40px;
}

.inputCategory:focus-visible {
  outline: none !important;
}

.btnWrapper {
  display: flex;
  gap: 0.5rem;
}

.btnStyles {
  background-color: transparent;
  border: none;
  color: #868e96;
  cursor: pointer;
}

.input:focus-visible {
  outline: none;
}

// .rejected,
// .pending,
// .approved {
//   color: $DARKPURPLE;
//   padding: 0.5rem;
//   border-radius: 4px;
// }

// .pending {
//   color: $DARKYELLOW;
// }

// .approved {
//   color: #1897a6;
// }

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.saveBtn {
  // width: 100%;
  background-color: $DARKPURPLE !important;
  color: white !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 5px;
  padding: 0px 40px !important;
  height: 45px !important;
  border-radius: 32px !important;
  border: 2px solid $DARKPURPLE !important;
}

.cancelBtn {
  // width: 100%;
  color: black !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 5px;
  padding: 0px 40px !important;
  height: 45px !important;
  border-radius: 32px !important;
  border: 2px solid $DARKPURPLE !important;
  background-color: white !important;
}

.textAreaReject {
  height: 100px;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  background: $LIGHTYELLOW2;
  resize: none;
  margin-top: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: Poppins;
}

.note {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  text-align: end;
}

.receiptImg {
  max-width: 400px;
  max-height: 400px;
  object-fit: contain;
  margin: auto;
  margin-top: 20px;
}

.receiptImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  padding: 0.5rem;
}

.textarea {
  height: 110px;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
  margin-top: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: Poppins;
}

// .dialog {
//   z-index: 101;
//   position: fixed;
//   margin: 0;
//   left: 50%;
//   transform: translateX(-50%);
//   width: 80vw;
//   max-width: 35rem;
//   display: flex;
//   flex-direction: column;
//   gap: 0.5rem;
//   justify-content: center;
//   border: none;
//   border-radius: 4px;
//   opacity: 0;
//   transition: opacity 0.3s, transform 0.3s;
// }

// .dialog.active {
//   top: 50%;
//   transform: translate(-50%, -50%);
//   opacity: 1;
// }

.dialog {
  z-index: 101;
  position: fixed;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50vw;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  border: none;
  // height: 10rem;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  padding: 1rem;
}

.dialog.active {
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.closeIcon {
  margin: 0;
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.modalHeading {
  text-align: center;
  margin: 0;
  font-family: "poppins";
  font-size: 18px;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.btnWrapper button {
  width: max-content;
  padding: 12px 40px;
}

.btnWrapper .cancel {
  background-color: $RED;
}

.textArea {
  // margin-bottom: 2rem;
  padding: 0.5rem;
  border: 1px solid #d9d9d9;
  resize: none;
  border-radius: 4px;
  font-family: "poppins";
}

.modalText {
  margin: 0;
  font-size: 14px;
  font-family: "poppins";
  text-align: center;
  // margin-bottom: 1rem;
}

.receiptImageWrapper {
  width: 45%;
  height: 20rem;
  margin: auto;
  padding: 0.5rem;
  border: 3px dashed lightgray;
}

.receiptImageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
  display: block;
}

@media screen and (max-width: 500px) {
  .dialog {
    width: 85vw;
    max-width: 85vw;
  }

  .receiptImageWrapper {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .topHeadContainer {
    display: block;
  }

  .filterContainer {
    display: block;
  }

  .input {
    width: 100%;
  }

  .gridContainer {
    margin-right: 0px;
  }

  .filterStyles {
    width: 100%;
  }

  .tableSubHeading {
    margin-bottom: 30px;
  }
}

.actionContainer {
  display: flex;

  div {
    background-color: $TransparentGREY;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;

    svg {
      height: 18px;
      width: 18px;
    }
  }
}

.statusCell {
  label {
    padding: 2px 8px;
    font-family: "Inter";
    font-weight: 500;
    line-height: 18px;
    font-size: 12px;
    width: fit-content;
    border-radius: 16px;
  }
}

.pending {
  background-color: $LIGHTYELLOW2;
  color: $PENDING_YELLOW;
}

.neutral {
  background-color: $LIGHTL_BLUE;
  color: $NEUTRAL_BLUE;
}

.approved {
  background-color: $LIGHTGREEN;
  color: $GREEN;
}

.rejected {
  background-color: $LIGHTRED;
  color: $RED;
}

.crossIcon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9999;
}
