@import "shared/utils/colors.scss";

.card {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
}

.cardContent {
    padding: 35px !important;
}

.btn {
    width: auto;
    padding: 10px 15px;
}

.upperGrid {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.bookTitle {
    color: var(--main-colors-primary, $DARKPURPLE);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.mainContainer {
    padding: 0px 20px;
    margin-top: 30px !important;
}

.bookImage {
    width: 280px;
    height: 360px;
    object-fit: cover;
}

.bookInfo {
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.gridSingleContainer {
    padding: 16px;
    margin-top: 0px !important;
}

.title {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.subTitle {
    color: var(--gray-gray-10, $BLACK);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
}

.description {
    color: var(--gray-gray-10, $BLACK);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-top: 10px;
}

.descriptionUrdu {
    color: var(--gray-gray-10, $BLACK);
    font-family: "Urdu" !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-top: 10px;
}

@media screen and (max-width:1380px) {
    .bookImage {
        width: 85% !important;
        object-fit: contain;
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:500px) {
    .upperGrid {
        display: block;
    }

    .btn {
        width: 100%;
        margin: 20px 0px;
    }

    .title {
        margin-top: 20px;
    }
}