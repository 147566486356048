@import "shared/utils/colors.scss";

.table {
    box-shadow: none !important;
    border-radius: 0px !important;
}

.table::-webkit-scrollbar {
    display: none;
}

.tableHead {
    th {
        border-top: 1px solid $GREY2;
        background-color: #f9fafb;
        color: var(--gray-gray-07, $TEXTGREY);
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        padding: 12px 16px;
    }
}

.tableRow {
    td {
        min-width: 180px !important;
    }
}

.container {
    box-shadow: none !important;
}

.topLevelContainer {
    background-color: white;
    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    //   0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
    border-radius: 4px;
    margin-top: 2rem;
}

.topHeadContainer {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 20px;
}

.tableHeading {
    color: var(--gray-900, black);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.tableSubHeading {
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.filterContainer {
    display: flex;
    align-items: center;
}

.gridContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 10px;
}

.searchIcon {
    position: absolute;
    top: 13px;
    left: 0;
    padding-left: 10px;
}

.input {
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border: 1px solid var(--gray-gray-06, $TEXTGREY);
    border-radius: 32px;
    padding: 10px 14px;
    padding-left: 40px;
}

.inputCategory:focus-visible {
    outline: none !important;
}

.btnStyles {
    background-color: transparent;
    border: none;
    color: #868e96;
    cursor: pointer;
}


@media screen and (max-width: 767px) {
    .topHeadContainer {
        display: block;
    }

    .filterContainer {
        display: block;
    }

    .input {
        width: 100%;
    }

    .gridContainer {
        margin-right: 0px;
    }

    .filterStyles {
        width: 100%;
    }

    .tableSubHeading {
        margin-bottom: 30px;
    }
}