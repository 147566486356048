.buttonWrapper {
    display: flex;
    gap: 8px;
    margin: auto;
}

.buttonWrapper button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.buttonWrapper {
    button {
        svg {
            color: #ADB5BD
        }
    }
}

.buttonWrapper {
    input {
        max-width: 7rem;
        text-align: center;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

@media screen and (min-width:767px) {
    .buttonWrapper {
        margin-left: auto;
        margin-right: 0;
    }
}