@import "shared/utils/colors.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.mainGrid {
  // height: 80vh;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none !important;
  }

  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  font-family: Poppins;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: $BLACK2;
}

.addNewBtn {
  background-color: $DARKPURPLE;
  border-radius: 32px;
  border: none !important;
  padding: 12px 24px;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  color: white !important;
  font-family: Poppins;
  font-weight: 500;
}

//banner

.bannerContainer {
  padding: 12px;
  border-radius: 16px;
  border: 1px solid $BORDERGREY;
  background-color: white;
  display: flex;
  gap: 24px;
}

.bannerImg {
  height: 200px;
  width: 300px;
  object-fit: cover;
  border-radius: 16px;
}

.actionTopContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bannerTitle {
  font-family: Poppins;
  font-size: 18px;
  line-height: 24px;
  color: $BLACK2;
  font-weight: 500;
}

.bannerDate {
  font-family: Poppins;
  font-weight: 400;
  color: $TEXTGREY;
  font-size: 12px;
  line-height: 18px;
}

.bannerHeadingContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.loginLabel {
  padding: 2px 8px;
  font-family: Inter;
  font-weight: 500;
  line-height: 18px;
  font-size: 12px;
  width: fit-content;
  border-radius: 16px;
  background-color: $LIGHTGREEN;
  color: $GREEN;
  display: flex;
  align-items: center;
}

.publicLabel {
  padding: 2px 8px;
  font-family: Inter;
  font-weight: 500;
  line-height: 18px;
  font-size: 12px;
  width: fit-content;
  border-radius: 16px;
  background-color: #f4f3ff;
  color: #5925dc;
  display: flex;
  align-items: center;
}

.actionContainer {
  display: flex;
  align-items: center;
  gap: 12px;

  div {
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    background: $TransparentGREY;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.bannerLoaderContainer {
  padding: 12px;
  border-radius: 16px;
  border: 1px solid $BORDERGREY;
  display: flex;
  gap: 24px;
}

.noDataCard {
  display: flex;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 3rem;

  label {
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $BLACK2;
  }
}

.htmlTitle {
  p {
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
  }

  h1 {
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
    font-family: "Poppins" !important;
    font-weight: 600 !important;
    font-size: 18px !important;
  }

  h2 {
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
  }
}

@media screen and (max-width: 700px) {
  .bannerContainer {
    display: block;
    padding: 18px !important;
  }
  .bannerImg {
    width: 100%;
    margin-bottom: 20px;
  }
  .actionContainer {
    margin-top: 10px;
  }
  .htmlTitle {
    p {
      margin: 0px;
      overflow: visible !important;
      text-overflow: inherit;
      white-space: break-spaces;
      width: 100%;
    }

    h1 {
      margin: 0px;
      overflow: visible !important;
      text-overflow: inherit;
      white-space: break-spaces;
      width: 100%;
    }

    h2 {
      margin: 0px;
      overflow: visible !important;
      text-overflow: inherit;
      white-space: break-spaces;
      width: 100%;
    }
  }
}
