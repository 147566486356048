@import "../../pages/membership/style.module.scss";
@import "shared/utils/colors.scss";

.dialogContentWrapper {
  position: relative;
}

.crossIcon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}

.crossIconViewCover {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: end;
}

.coverImg {
  width: 100%;
  height: 300px;
}

.customBtn {
  margin-top: 10px;
}

.table {
  // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
  box-shadow: none !important;
  // margin-top: 35px;
  border-radius: 0px !important;
}

.name {
  font-size: 12px;
  color: $TEXTGREY;
}

.table::-webkit-scrollbar {
  display: none;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.topTitle {
  color: var(--gray-900, $BLACK);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.bookDetailContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.reasonTextArea {
  width: 100%;
  resize: none;
  height: 100px;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.btnContainer {
  display: flex;
  margin-top: 0px;
}

.title {
  margin-bottom: 0px;
}

.btnCancel {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;
  width: auto;
  padding: 0px 20px;
  background-color: transparent;
  border: 1px solid $DARKPURPLE;
  color: $DARKPURPLE;
}

.tableHead {
  th {
    border-top: 1px solid $GREY2;
    background-color: #f9fafb;
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 12px 16px;
  }
}

.btnSave {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: auto;
  padding: 0px 20px;
}

.gridContainerSearch {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // margin-right: 10px;
}

.bookCover {
  width: 180px;
  height: 210px;
  object-fit: fill;
  border-radius: 20px;
}

.cardContent {
  padding-bottom: 16px !important;
  display: flex;
  min-height: 40vh;
  cursor: pointer;
}

.card {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
  position: relative;
}

.checkbox {
  color: $DARKPURPLE !important;
  padding: 10px !important;
}

.description {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
}

.copiesContainer {
  display: flex;
  //   justify-content: space-around;
  justify-content: start;
  gap: 3rem;
  align-items: center;
}

.headings {
  color: var(--gray-900, $BLACK);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-left: 15px;
  width: 100%;

  div {
    margin-bottom: 5px;
  }
}

.featureContainer {
  color: var(--gray-900, $BLACK);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-left: 15px;
  width: 100%;
  position: relative;
}

.copyTitle {
  color: $TEXTGREY;
  font-size: 14px;
}

.icon {
  width: auto;
  background: white;
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 3px;
  cursor: pointer;
}

.bookCover2 {
  width: 70px;
  height: 90px;
  object-fit: fill;
}

.book::-webkit-scrollbar {
  display: none;
}

// .btnClose {
//     width: auto;
// }

.imgContainer {
  display: flex;
  justify-content: center;
  position: relative;
}

.bookCoverTable {
  width: 37.333px;
  height: 40px;
}

.noUserCard {
  display: flex;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  align-items: center;
}

.uploadPicture {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-top: 30px;
  cursor: pointer;
  object-fit: cover;
}

.uploadImageContainer {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  cursor: pointer;
  border: 1px solid $GREY2;
  flex-direction: column;

  svg {
    fill: $TEXTGREY;
  }
}

.uploadedImg {
  height: 100px;
  width: 100%;
  object-fit: cover;
}

.uploadIcon {
  position: absolute;
  bottom: 10px;
  color: black;
}

.topPublisherContainer {
  height: 350px;
  overflow: scroll;
  margin-bottom: 20px;
}

.topPublisherContainer::-webkit-scrollbar {
  display: none;
}

.publisherContainer {
  position: relative;
  padding: 10px;
  border-radius: 14px;
  border: 1px solid var(--main-colors-secondary, $PINK);
  box-sizing: border-box;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  cursor: pointer;
}

.publisherContainer:hover {
  background-color: $PINK;

  div {
    div {
      color: white;
    }
  }

  svg {
    path {
      fill: white;
    }
  }
}

.containerHeadings {
  color: var(--gray-gray-10, $BLACK);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.prevIcon {
  position: absolute;
  cursor: pointer;
  min-width: 0px !important;
  padding: 0px !important;
}

.containerSubHeadings {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
}

.profilePic {
  width: 93.333px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}

.publisherHouseImg {
  width: 35px;
  height: 35px;
  border-radius: 30px;
}

.checkedIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.bookTitle {
  color: var(--gray-gray-09, $BLACK);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  margin-bottom: .5rem;
  gap: .5rem;

  div:first-child {
    flex: 1;
  }

  div:last-child {
    flex: 3 !important;
  }

  span:last-child {
    flex: 2;
    color: var(--main-colors-primary, $DARKPURPLE);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 10px;
  }
}



.errorText {
  height: 30px;
  font-size: 12px;
  color: red;
  display: flex;
  justify-content: start;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.dateTitle {
  color: var(--gray-gray-10, $BLACK);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.active {
  background: $LIGHTGREEN;
  color: green;
  padding: 5px 10px;
  border-radius: 5px;
}

.upcoming {
  background: #ace9f0;
  color: darkblue;
  padding: 5px 10px;
  border-radius: 5px;
}

// .featureButton {
// position: absolute;
// bottom: 0;
// }

.bestSellersCard {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  height: 330px;
  max-height: 350px;
}

.bestSellersCard>.imageWrapper {
  flex: 1;
  border-radius: 5px;
  overflow: hidden;
}

.bestSellersCard .headings {
  flex: 1;
}

.imageWrapper img {
  // width: 100%;
  // height: 100%;
  // object-fit: fill;
  // border-radius: 5px;
  // transition: all linear 0.5s;
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
  transition: all linear 0.5s;
  aspect-ratio: 16/9;
  display: block;
}

.imageWrapper img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.bestSellersCard>.headings {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.bestSellersCard>.headings>.titleWrapper {
  color: #9a469b;
  font-weight: bold;
}

.bestSellersCard>.headings>.titleWrapper>.titleSpan,
.bestSellersCard>.headings>.description>.descriptionTitle.active,
.bestSellersCard>.headings>.categoryWrapper>.categoryTitle {
  color: black;
  font-weight: bold;
  margin-right: 0.5rem;
}

.bestSellersCard>.headings>.categoryWrapper .category {
  padding: 0.3rem;
  border-radius: 5px;
  background-color: #fff3ff;
  color: #9a469b;
  font-weight: bold;
  font-size: 12px;
}

.categoryContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}

// .readMoreBtn {
//   display: flex;
//   gap: 0.2rem;
//   margin-left: 0.3rem;
//   align-items: center;
//   color: #9a469b;
// }

.viewDeatilsBtn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.2rem 1rem;
  background-color: #9a469b;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #9a469b;
  transition: all linear 0.5s;
  margin-top: 0.5rem;
  margin-top: auto;
  align-self: flex-start;
}

.viewDeatilsBtn:hover {
  background-color: white;
  color: #9a469b;
  border: 1px solid #9a469b;
}

@media screen and (max-width: 500px) {
  .bestSellersCard {
    flex-direction: column;
    gap: 1rem;
    height: auto;
    max-height: none;
  }

  .bestSellersCard .imageWrapper {
    flex: none;
    height: 300px;
  }

  .viewDeatilsBtn {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 1500px) {
  .bestSellersCard>.headings {
    flex: 1;
  }
}

@media screen and (min-width: 768px) and (max-width: 1190px) {
  .bestSellersCard>.headings {
    flex: 2;
  }
}

@media screen and (max-width: 550px) {
  .cardContent {
    display: block;
  }

  .headings {
    margin-left: 0px;
  }

  .bookCover {
    width: 100px;
    height: 135px;
    margin-bottom: 15px;
  }

  .topContainer {
    display: block;
  }

  .btnSave {
    width: 100%;
    margin-top: 20px;
  }

  .topPublisherContainer {
    padding: 0px;
  }

  .featureContainer {
    margin-left: 0px;
  }

  .containerHeadings {
    font-size: 14px;
  }

  .containerSubHeadings {
    font-size: 12px;
  }

  .featureButton {
    position: relative;
  }
}

@media screen and (max-width: 750px) {
  .bookTitle {
    font-size: 16px;

    span {
      font-size: 16px;
    }
  }
}