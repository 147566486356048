@import "shared/utils/colors.scss";

.crossIcon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
}

.title {
    color: var(--gray-900, black);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.textarea {
    width: 100%;
    border: 1.5px solid $TEXTGREY;
    margin-top: 20px;
    border-radius: 7px;
    height: 100px;
    resize: none;
    font-family: Poppins;
    padding: 10px;
    box-sizing: border-box;
}

.textarea:focus-visible {
    outline: none;
}

.btn {
    margin-top: 20px;
    width: 100%;
}

.radio {
    label {
        span {
            color: $DARKPURPLE !important;
        }
    }
}

.ratingContainer {
    padding: 10px;
    border: 1px solid $TEXTGREY;
    border-radius: 10px;
    margin-top: 20px;
}