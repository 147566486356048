@import "shared/utils/colors.scss";

.logo {
    width: 100%;
    height: 100px;
    margin: 25px 0px;
}

.sidebar {
    height: 100vh;


    div {
        background-color: white;
    }
}



.menu {
    ul {
        li {
            color: var(--gray-gray-07, $GREY);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            a {
                margin: 0px 10px;
                padding: 10px !important;
            }
        }
    }
}

.active {
    a {
        background-color: $PURPLE;
        color: $DARKPURPLE !important;
        border-radius: 5px !important;
    }

    a:hover {
        background-color: $PURPLE !important;
        color: $DARKPURPLE !important;
        border-radius: 5px !important;
    }
}

.subMenuContainer {
    div {
        background-color: $LIGHTGREY;
        margin: 10px;

        ul {
            li {
                a {
                    margin: 0px !important;
                }
            }
        }
    }
}

.borderSubMenu {
    border-bottom: 1px solid $GREY2;
}

.subMenuActive {
    color: $DARKPURPLE !important;
}

.logoContainer {
    display: flex;
    justify-content: center;
}

.componentContainer {
    padding: 24px;
    background-color: $MAINBACKGROUND;
    height: 90vh;
    overflow: scroll;
    box-sizing: border-box;
}

.componentContainer::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 900px) {
    .logo {
        width: 75%;
    }
}