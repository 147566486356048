@import "shared/utils/colors.scss";

.crossIcon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
}

.title {
    color: var(--gray-gray-10, $BLACK);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    margin-left: 10px;
}

.textArea {
    width: 100%;
    resize: none;
    border-radius: 10px;
    height: 200px;
    margin-top: 14px;
    padding: 10px;
    box-sizing: border-box;
    color: var(--gray-gray-08, $BLACK);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.errorText {
    height: 30px;
    font-size: 12px;
    color: red;
    display: flex;
    justify-content: start;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}