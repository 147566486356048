@import "shared/utils/colors.scss";

// .crossIcon {
//     cursor: pointer;
//     position: absolute;
//     right: 20px;
//     top: 20px;
// }

.crossIcon {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: end;
}

.dialogContent {
    padding: 24px !important;
}

// .dialogContent::-webkit-scrollbar {
//     display: none;
// }

.dialogContent:not(:hover)::-webkit-scrollbar {
    width: 0;
}


.dialogContent::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.dialogContent::-webkit-scrollbar {
    width: 4px;
    /* Adjust the width as needed */
    background-color: #F5F5F5;
}

.dialogContent::-webkit-scrollbar-thumb {
    background-color: $DARKPURPLE;
}

.heading {
    color: var(--gray-gray-10, $BLACK);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    span {
        color: $DARKPURPLE;
    }
}

.errorText {
    height: 20px;
    font-size: 12px;
    color: red;
    display: flex;
    justify-content: start;
}

.input {
    width: 100%;
    box-sizing: border-box;
    height: 45px;
    border-radius: 32px;
    border: 1px solid var(--gray-gray-06, $GREY);
    color: $BLACK;
    padding: 10px;
    font-family: Poppins;
    font-weight: 400;
    // margin: 10px 0px 20px 0px;
}

.input:focus-visible {
    outline: none;
}

.dropdownMenu {
    height: 45px !important;
    border: 1px solid $GREY !important;
    width: 100% !important;
    border-radius: 32px !important;
    text-transform: none !important;
    display: flex !important;
    justify-content: space-between !important;
    padding: 10px !important;
    color: $BLACK !important;
    margin: 10px 0px 20px 0px !important;
}

.icon {
    color: $GREY;
}

.textarea {
    width: 100%;
    box-sizing: border-box;
    height: 150px;
    border-radius: 20px;
    resize: none;
    padding: 10px;
    border-color: $GREY;
    // margin: 10px 0px 20px 0px !important;
    color: $BLACK;
    font-family: Poppins;
}

.textarea:focus-visible {
    outline: none;
}



.title {
    color: var(--gray-900, black);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 20px;
}