@import "shared/utils/colors.scss";

.closeIcon {
  padding: 8px;
  border: 1px solid #dce5ef;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.title {
  font-size: 24px;
  line-height: 36px;
  color: $DARKPURPLE;
  font-family: Poppins;
  font-weight: 600;
}

.iconContainer {
  display: flex;
  justify-content: end;
}

.subLabel {
  font-size: 18px;
  line-height: 27px;
  color: $DARKPURPLE;
  font-family: Poppins;
  font-weight: 600;
}

.selected {
  height: 8px !important;
  width: 8px !important;
  background-color: $PINK;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}

.radioBorder {
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  border: 1.5px solid #868e96;
}

.radioBorderSelected {
  border: 1.5px solid #ef437b;
}

.singleRadio {
  display: flex;
  gap: 6px;
}

.radioLabel {
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  color: #868e96;
  font-weight: 400;
}

.radioSelectedLabel {
  color: $PINK;
}

.radioContainer {
  display: flex;
  gap: 24px;
}

.radioLabelContainer {
  display: flex;
  justify-content: space-between;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.infoContainer {
  background: #fbfbfb;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 24px 18px;
}

.discountContainer {
  background: #fbfbfb;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 24px 18px;
  margin-top: 24px;
}

.checkbox {
  border: 1.5px solid $TEXTGREY;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 16px;
}

.checked {
  border: 1.5px solid $PINK;
  background-color: $PINK;
}

.singleSelection {
  display: flex;
  gap: 6px;
  z-index: 99999999;
}

.selectionLabel {
  font-size: 16px;
  line-height: 24px;
  color: $DARKPURPLE;
  font-family: Poppins;
  font-weight: 400;
  color: #868e96;
}

.selectedLabel {
  color: #ef437b;
}

.selectionContainer {
  display: flex;
  justify-content: space-between;
}

.input {
  width: 100%;
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.input::placeholder {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.inputLabel {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.btnContainer {
  display: flex;
  gap: 8px;
}

.addBtn {
  width: 160px;
  height: 48px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: 32px;
  background-color: $DARKPURPLE;
  color: #fbfbfb;
}

.cancelBtn {
  width: 160px;
  height: 48px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #868e96;
  border: 1px solid #868e96;
  border-radius: 32px;
  background-color: white;
}

.btnContainer {
  display: flex;
  justify-content: end;
}

.textarea {
  border: 1px solid #adb5bd;
  border-radius: 32px;
  resize: none;
  padding: 12px;
  height: 150px;
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.dialogContainer::-webkit-scrollbar {
  display: none;
}

.cardDescription {
  color: var(--gray-900, #adb5bd);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 5px 0px;
}

.uploadContainer {
  align-items: center;
  color: rgb(173, 181, 189);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 190px;
  justify-content: center;
  position: relative;
  border-width: 1px;
  border-style: dotted;
  border-color: rgb(173, 181, 189);
  border-image: initial;
  border-radius: 8px;
  background-color: white;
}

.uploadedImageContainer {
  height: 190px;
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
}

.cardsSection {
  background: #fbfbfb;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 24px 18px;
}

.deleteIcon {
  position: absolute;
  top: -5px;
  right: -5px;
  background: white;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #e9ecef;
  width: 18px;
  height: 18px;

  path {
    stroke: red;
  }
}

.errorText {
  height: 20px;
  font-size: 12px;
  color: red;
  display: flex;
  justify-content: start;
}

.classRoomIcon {
  height: 30px;
  width: 30px;
}

.selectedSchool {
  display: flex;
  align-items: center;
  font-family: Poppins;
  gap: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 12px;
  border: 1px solid #adb5bd;
  border-radius: 32px;
}
