@import "shared/utils/colors.scss";

.cardContainer {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
  padding-bottom: 0px !important;
}

.cardContentWrapper {
  padding-bottom: 16px !important;
  position: relative;
}

.gridContainer {
  display: flex;
  align-items: center;
}

.mouBtn {
  width: auto;
  padding-left: 25px;
  padding-right: 25px;
}

.nameLabel {
  color: var(--gray-gray-08, black);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.username {
  font-size: 12px !important;
  color: #868E96 !important;
  text-align: center;
  display: block;
  max-width: 385px;
  line-break: anywhere;
  margin: auto;
}



.status::after {
  content: ':';
}

.publisherStatus {
  position: absolute;
  display: flex;
  gap: 1rem;
  top: 0;
  right: 0;
  padding-top: 1rem;
  padding-right: 1rem;
}

.publisherStatus li {
  margin: 0 !important;
}

.statusWrapper {
  position: absolute;
  right: 0;
  top: 0;
  padding: 1rem;
  margin-right: 0px !important;
}

.statuses {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: end;
  top: 0;
  right: 0;
  padding: 1rem;
}

.mouStatus {
  color: #B42318
}

.statuses li {
  margin: 0 !important;
}

@media screen and (max-width:768px) {
  .statusWrapper {
    position: relative;
    padding: 0;
  }

  .statuses {
    position: relative;
    flex-direction: row;
    align-items: center;
    padding: 0;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }


}

.rejected {
  color: $DARKPURPLE;
  padding: 0.5rem;
  border-radius: 4px;
}

.active {
  color: $GREENBLUE;
}

.pending {
  color: $DARKYELLOW;
}


.sectionsLabel {
  color: var(--text-color, $NAVY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upperContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $GREY2;
  padding-bottom: 25px;
}

.listing {
  display: flex;
  padding-left: 0px;
  flex-wrap: wrap;
  margin-bottom: 0px;
  row-gap: 1rem;
  margin-top: 0;
  padding: 1rem .5rem .5rem;

  li {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: var(--text-color, $NAVY);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;

    span {
      margin-left: 5px;
    }
  }
}

.listing {
  svg {
    path {
      stroke: #9a469b;
    }
  }
}

.addressIconWrapper {
  svg {
    path {
      stroke: #9a469b;
    }
  }
}

.toolTipWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 0.2rem;
}

.accordianSummaryContent {
  color: var(--gray-gray-07, #868e96);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.accordianListing {
  padding: 0;
}

@media screen and (max-width: 767px) {
  .upperContainer {
    display: block;
  }

  .mouBtn {
    width: 100%;
    margin-top: 20px;
  }

  .gridContainer {
    align-items: start;
  }

  .listing {
    li {
      margin: 5px;
    }
  }
}