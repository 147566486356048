@import "shared/utils/colors.scss";

// .crossIcon {
//     cursor: pointer;
//     position: absolute;
//     right: 20px;
//     top: 20px;
// }

.crossIcon {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: end;
}

.card {
    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
    // margin-top: 35px;
    padding: 10px;
    box-shadow: none !important;
}

.noUserCard {
    display: flex;
    justify-content: center;
    padding: 40px;
    flex-direction: column;
    align-items: center;
}

.receipt {
    height: 200px;
    width: 100%;
    object-fit: contain;
}

.title {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.subTitle {
    color: var(--gray-gray-10, $BLACK);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
}

.grid {
    padding: 0px 16px;
    margin-top: 20px !important;
}

.title {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.imgContainer {
    display: flex;
    justify-content: center;
}

.uploadBtn {
    width: 100%;
    height: 150px;
    background-color: #eaeef1;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 20px 0px;
}

.uploadImg {
    width: 100%;
    height: 150px;
    object-fit: contain;
    margin: 20px 0px;

}

input:focus-visible {
    outline: none;
}

.subTitle {
    color: var(--gray-gray-10, $BLACK);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
}

.errorText {
    height: 20px;
    font-size: 12px;
    color: red;
    display: flex;
    justify-content: start;
}