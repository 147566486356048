@import "shared/utils/colors.scss";

// .crossIcon {
//     cursor: pointer;
//     position: absolute;
//     right: 20px;
//     top: 20px;
// }

.crossIcon {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: end;
}

.cancelBtn {
    margin-top: 20px;
    background-color: white;
    border: 1.5px solid $DARKPURPLE;
    color: $DARKPURPLE;
    margin-left: 10px;
}



.title {
    color: var(--gray-900, black);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}



.inputContainer {
    display: flex;
    justify-content: space-between;

    div {
        width: 85%;
    }
}

input {
    width: 98%;
    box-sizing: border-box;
    height: 45px;
    border-radius: 32px;
    border: 1px solid var(--gray-gray-06, #ADB5BD);
    color: #0F1106;
    padding: 10px;
    font-family: Poppins;
    font-weight: 400;
}

.inputRight {
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: end;
    width: 95% !important;
}

.input:focus-visible {
    outline: none;
}

.heading {
    color: var(--gray-gray-10, $BLACK);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;

    span {
        color: $DARKPURPLE;
    }
}

.addBtn {
    margin-top: 20px;
}

.genres {
    padding: 5px;
    background-color: $PURPLE;
    color: $DARKPURPLE;
    font-size: 12px !important;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.closeIcon {
    cursor: pointer;
    margin-left: 5px;
    font-size: 14px !important;
}

.tagContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.error {
    color: red;
    font-size: 12px;
    height: 20px;
    text-align: start;
    width: 100% !important;
}