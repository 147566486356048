@import "shared/utils/colors.scss";

.paginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.nextBtn {
  border: 1px solid $GREY;
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: var(--gray-gray-10, black);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 10px;
  cursor: pointer;

  span {
    margin-right: 10px;
  }
}

.previousBtn {
  border: 1px solid $GREY;
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: var(--gray-gray-10, black);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 10px;
  cursor: pointer;

  span {
    margin-left: 10px;
  }
}

button:disabled {
  color: #adb5bd;
  cursor: not-allowed;
}

button:disabled svg path {
  fill: #adb5bd;
  stroke: #adb5bd;
}
@media screen and (max-width: 700px) {
  .nextBtn,
  .previousBtn {
    display: none;
  }

  .paginationContainer {
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
}
