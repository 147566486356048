.container {
    color: grey;
    margin-right: 5px;
    font-size: 13px;
    font-weight: 500;
}

.ActiveContainer {
    color: black;
    margin-right: 5px;
    font-size: 13px;
    font-weight: 500;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.label {
    margin-right: 5px;
    cursor: pointer;
}

@media screen and (max-width:992px) {
    .topLevelContainer {
        margin-left: 50px;
    }
}

@media screen and (max-width:500px) {
    .label {
        margin-right: 2px;
        font-size: 8px;
    }
}