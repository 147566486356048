@import "shared/utils/colors.scss";

.button {
    padding: 9px 16px !important;
    border-radius: 32px !important;
    border: 1px solid var(--gray-gray-06, $TEXTGREY) !important;
    background: var(--White, white) !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    display: flex !important;
    justify-content: space-between !important;
    min-width: 120px !important;
    color: $TEXTGREY !important;
    text-transform: none !important;
    font-size: 12px !important;
    font-family: Poppins !important;
}

.selectedOption {
    position: absolute;
    padding: 5px;
    background: $PURPLE;
    font-size: 12px;
    margin-top: 5px;
    color: $DARKPURPLE;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
}

.selectionOptionIcon {
    cursor: pointer;
    font-size: 12px !important;
    margin-left: 5px;
}

@media screen and (max-width: 500px) {
    .button {
        width: 100%;
        margin-top: 20px !important;
    }
}