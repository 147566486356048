@import "shared/utils/colors.scss";

.amount {
  width: 100%;
  margin-top: 10px;
}

.tabs {
  font-family: "Poppins" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 23px !important;
}

.plansCategoryTabs {
  font-family: "Poppins" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 23px !important;
  width: 25%;
  color: #adb5bd !important;
  background-color: #f2f7fb !important;
}

.plansCategoryLabel {
  font-family: "Poppins" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 18px !important;
}

.activeLabel {
  color: $PINK !important;
  background-color: #ef437b1a !important;
}

.addBtn {
  padding: 12px 39px;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
}

.card {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
  margin-top: 24px;
}

.title {
  color: var(--gray-900, $BLACK);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.radio {
  margin-left: 20px;
  font-family: Poppins;

  label {
    margin-right: 0px;
    font-family: Poppins;

    span {
      color: $DARKPURPLE !important;
      font-family: Poppins;
    }
  }
}

.table {
  box-shadow: none !important;
  border-radius: 0px !important;
  overflow-x: auto;
}

.table::-webkit-scrollbar {
  display: none;
}

.container {
  box-shadow: none !important;
  // border-bottom-left-radius: 18px !important;
  // border-bottom-right-radius: 18px !important;
}

.tableHead {
  th {
    // border-top: 1px solid $GREY2;
    background-color: #f9fafb;
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 12px 24px;
  }
}

.tableRow {
  td {
    min-width: 180px !important;
    padding: 24px;
  }
}

.actionBtn {
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: $TEXTGREY;
    margin-right: 10px;
  }
}

.topLevelContainer {
  background-color: white;
  border-radius: 4px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 31px;
}

.outerMostContainer {
  background: white;
  border: 1px solid #e4e7ec;
  border-radius: 16px;
}

.paginationContainer {
  background: white;
  border-bottom-left-radius: 18px !important;
  border-bottom-right-radius: 18px !important;
}

.topHeadContainer {
  display: flex;
  justify-content: space-between;
  padding: 24px 20px;
}

.tableHeading {
  color: var(--gray-900, black);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.tableSubHeading {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.gridContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 10px;
}

.input {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid var(--gray-gray-06, $TEXTGREY);
  border-radius: 32px;
  padding: 10px 14px;
  padding-left: 40px;
}

.input:focus-visible {
  outline: none;
}

.searchIcon {
  position: absolute;
  top: 13px;
  left: 0;
  padding-left: 10px;
}

.filterContainer {
  display: flex;
  align-items: center;
}

.btnContainer {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.btnCustom {
  width: auto;
  border: 1.5px solid $DARKPURPLE;
  margin-right: 5px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.btnInsideOut {
  background: transparent !important;
  color: black;
  width: auto;
  border: 1.5px solid $DARKPURPLE;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.errorText {
  height: 20px;
  font-size: 12px;
  color: red;
  display: flex;
  justify-content: start;
}

.topLevel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 20px;
  // margin-bottom: 10px;
  margin-top: 40px;
}

.selection {
  width: 98%;
  box-sizing: border-box;
  height: 45px;
  border-radius: 32px;
  border: 1px solid var(--gray-gray-06, #adb5bd);
  color: #0f1106;
  padding: 10px;
  font-family: Poppins;
  font-weight: 400;
}

.selection:focus-visible {
  outline: none;
}

.inputField::-webkit-outer-spin-button,
.inputField::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.inputField:focus-visible {
  outline: none;
}

.textarea {
  width: 99%;
  height: 90px;
  border-radius: 18px;
  resize: none;
  border: 1px solid var(--gray-gray-06, #adb5bd);
  box-sizing: border-box;
  color: #0f1106;
  padding: 10px;
  font-family: Poppins;
  font-weight: 400;
}

.titleHead {
  color: var(--gray-900, #0f1106);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.checkbox {
  color: $DARKPURPLE !important;
  padding: 0px !important;
}

.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
}

.noData h5 {
  margin: 0;
}

.actionContainer {
  display: flex;

  div {
    background-color: $TransparentGREY;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;

    svg {
      height: 18px;
      width: 18px;
    }
  }
}

@media screen and (max-width: 767px) {
  .topHeadContainer {
    display: block;
  }

  .filterContainer {
    display: block;
  }

  .input {
    width: 100%;
  }

  .gridContainer {
    margin-right: 0px;
  }

  .filterStyles {
    width: 100%;
  }

  .tableSubHeading {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 600px) {
  .topLevel {
    display: block;
  }

  .radio {
    margin-left: 0px;
    margin-right: 20px;
  }
}

.inactiveIcon {
  stroke: $RED;
}

.activeIcon {
  stroke: green;
}
