@import "shared/utils/colors.scss";

.heading {
  font-family: Poppins;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: $BLACK2;
}

.manageContainer {
  min-height: 75vh;
  border-radius: 16px;
  border: 1px solid $BORDERGREY;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.textEditorContainer > div:first-child > div:first-child {
  border: 1.4px solid $BORDERGREY;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.textEditorContainer > div:first-child > div:last-child {
  border: 1.4px solid $BORDERGREY;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  height: 70px;

  &::-webkit-scrollbar-track {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #f7a1bd;
  }

  scrollbar-color: #f7a1bd transparent !important;
  scrollbar-width: thin !important;
}

.label {
  font-family: Poppins;
  font-size: 14x;
  line-height: 21px;
  color: $BLACK2;
  font-weight: 500;
}

.subLabel {
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  color: $BLACK2;
  font-weight: 400;
  color: $TEXTGREY;
  width: 100%;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}

.checkbox {
  border: 1.5px solid $TEXTGREY;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.checked {
  border: 1.5px solid $PINK;
  background-color: $PINK;
}

.selectionLabel {
  font-family: Poppins;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: $BLACK2;
}

.uploadContainer {
  border: 1px solid $BORDERGREY;
  border-radius: 16px;
  border-style: dotted;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  gap: 10px;

  label {
    color: $TEXTGREY;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    width: 70%;
    text-align: center;
  }
}

.uploadedPicture {
  height: 25vh;
  border-radius: 16px;
  width: 100%;
  object-fit: contain;
  border: 1px solid $BORDERGREY;
}

.deleteIcon {
  position: absolute;
  top: -20px;
  right: -10px;
  padding: 10px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid $BORDERGREY;
  cursor: pointer;

  path {
    stroke: $RED;
  }
}

.imgContainer {
  position: relative;
}

.urlInput {
  width: 100%;
  border: 1px solid $BORDERGREY;
}

.addBtn {
  padding: 10px 16px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 22px;
  font-size: 14px;
  border-radius: 32px;
  background-color: $DARKPURPLE;
  color: white;
  border: none;
  cursor: pointer;
  width: 150px;
}

.error {
  color: $RED;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  font-family: Poppins;
}

@media screen and (max-width: 600px) {
  .addBtn {
    width: 100%;
  }
}
