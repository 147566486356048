@import "../../../pages/review/style.module.scss";

.table {
    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
    box-shadow: none !important;
    // margin-top: 35px;
    border-radius: 0px !important;

}

.tableItem {
    color: var(--gray-gray-08, black);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
}

.actionBtn {
    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: $TEXTGREY;
        margin-right: 10px;
    }
}

.tableHeading {
    color: var(--gray-900, black);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.tableSubHeading {
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.tableHead {
    th {
        border-top: 1px solid $GREY2;
        background-color: #F9FAFB;
        color: var(--gray-gray-07, $TEXTGREY);
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        padding: 12px 16px;

    }
}

.topBtnContainer {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 20px;
}