@import "shared/utils/colors.scss";

.cardContainer {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
  position: relative;
}

.statsLabel {
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  color: black;
  position: absolute;
  bottom: 16px;
}

.cardContentContainer {
  padding: 16px !important;
  min-height: 100px;
}

.icon {
  color: $DARKPURPLE;
  margin-right: 10px;
}

.iconContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.label {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
