@import "shared/utils/colors.scss";

.button {
    width: 100%;
    background-color: $DARKPURPLE;
    border: none;
    color: white;
    border-radius: 32px;
    padding: 10px;
    cursor: pointer;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

