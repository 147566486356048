@import "shared/utils/colors.scss";

.card {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
}

.cardContent {
    padding: 24px !important;
}

.approveBtn {
    background-color: $DARK_GREEN !important;
    border: 1px solid $DARK_GREEN !important;
}

.rejectBtn {
    background-color: $RED !important;
    border: 1px solid $RED !important;
}

.selectionContainer {
    padding: 0px 20px;
    margin-top: 20px !important;
}

.title {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.btnContainer {
    margin: 20px 0px;
    display: flex;
    justify-content: end;
}

.btnCustom {
    width: auto;
    border: 1.5px solid $DARKPURPLE;
    margin-left: 15px;
    padding-left: 20px;
    padding-right: 20px;
    max-height: 50px
}

.btnInsideOut {
    background: transparent !important;
    color: black;
    width: auto;
    border: 1.5px solid $DARKPURPLE;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15px;
    max-height: 50px
}

.editContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}


.btn {
    width: 80%;
    height: 35px;
    background: none;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px 0px 15px;
}

.textarea {
    border: 1.5px solid $TEXTGREY;
    border-radius: 5px;
    resize: none;
    width: 80%;
    padding: 6px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: Poppins;
    height: 80px;
}

.textAreaOther {
    border: 1.5px solid $TEXTGREY;
    border-radius: 5px;
    resize: none;
    width: 100%;
    padding: 6px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: Poppins;
    margin-top: 10px;
    height: 100px;
}

.textarea:focus-visible,
.textAreaOther:focus-visible {
    outline: none;
}

.section {
    border: 1px solid $GREY2;
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
}

.sectionHeading {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.singleSection {
    border: 1px solid $GREY2;
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.radio {
    margin-right: 20px;

    label {
        margin-right: 0px;

        span {
            color: $DARKPURPLE !important;
        }
    }
}



@media screen and (max-width:900px) {
    .btn {
        width: 100%;
    }

    .textarea {
        width: 100%;
    }
}

@media screen and (max-width:1066px) {
    .btnCustom {
        font-size: 13px;
    }

    .btnInsideOut {
        font-size: 13px;
    }
}

@media screen and (max-width:550px) {
    .btnCustom {
        width: 100%;
        margin-bottom: 20px;
    }

    .btnInsideOut {
        width: 100%;
    }

    .btnContainer {
        display: block;
    }

    .editContainer {
        display: block;
    }

    .btnInsideOut {
        margin-bottom: 20px;
    }
}