@import "shared/utils/colors.scss";

.title {
    color: var(--gray-900, black);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.crossIcon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
}

.subTitle {
    color: var(--gray-gray-10, #0F1106);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
}

.checkbox {
    color: $DARKPURPLE !important;
}

.btnCustom {
    margin-top: 10px;
}