.input {
    border: none !important;

    div {
        padding: 0px !important;

        input {
            width: 100%;
            box-sizing: border-box;
            height: 45px;
            border-radius: 32px;
            border: 1px solid var(--gray-gray-06, #ADB5BD);
            color: #0F1106;
            padding: 10px !important;
            font-family: Poppins;
            font-weight: 400;
            font-size: 13px !important;
            margin-top: 5px;
        }

        fieldset {
            border: none !important;
        }
    }
}