@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.bookDetailWrapper {
  width: 100%;
  border-radius: 20px;
  background-color: white;
  padding-left: 24px;
  padding-right: 24px;
  width: 85%;
  margin-top: 15px;

}

.bookDetailContainer {
  background-color: white;
  max-height: 249px;
  min-height: 249px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 24px;
  padding-bottom: 24px;
  gap: 10px;
}

.bookDetailContainer::-webkit-scrollbar,
.bookStatsInfoContainer::-webkit-scrollbar {
  width: 5px;
}

.bookDetailContainer::-webkit-scrollbar-thumb,
.bookStatsInfoContainer::-webkit-scrollbar-thumb {
  // background-color: $GREEN;
  border-radius: 100px;
}

.bookDetailContainer::-webkit-scrollbar-track,
.bookStatsInfoContainer::-webkit-scrollbar-track {
  border: 1px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
  background-color: #f1f3f6;
}

.bookStatsInfoContainer {
  background-color: white;
  border-radius: 20px;
  max-height: 198px;
  min-height: 198px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 60px;
  padding: 24px;
  width: 85%;
  overflow-y: scroll;

}

.publisherInfoContainer {
  background-color: white;
  border-radius: 20px;
  max-height: 83px;
  min-height: 83px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px;
  gap: 10px;
  width: 85%;
}

.flagIcon {
  height: 22px;
  width: 22px;

  path {
    stroke-width: 1.5px;
  }
}

.heartIcon {
  height: 24px;
  width: 24px;
}

.bookTitle {
  text-align: left;
  color: var(--main-colors-primary, $DARKPURPLE);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.authorName {

  color: var(--main-colors-secondary, $PINK);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.starIcon {
  height: 16px;
  width: 16px;
}

.ratingLabel {
  font-family: PoppinsRegular;
  font-size: 12px;
  // color: $Yellow;
  text-align: left;
}

.ratingCount {
  font-family: PoppinsRegular;
  font-size: 12px;
  // color: $LIGHT_GREY;
  text-align: left;
}

.descriptionLabel {
  text-align: left;
  color: var(--gray-gray-09, $BLACK);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.descriptionLabelUrdu {
  text-align: left;
  color: var(--gray-gray-09, $BLACK);
  font-family: "Urdu";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.btnGrupContainer {
  flex-direction: row;
}

.btnContainer {
  height: 57px !important;
  width: 50% !important;
  // background-color: $Secondary !important;
  border-radius: 32px !important;
  font-size: 16px !important;
  color: white !important;
  gap: 10px !important;
}

.assignBtnContainer {
  min-height: 57px !important;
  max-height: 57px !important;
  height: 100%;
  width: 100% !important;
  border-radius: 32px !important;
  font-size: 16px !important;
  color: white !important;
  gap: 10px !important;
}

.btnIcon {
  height: 24px;
  width: 24px;
}

.greenBtn {
  background-color: $DARK_GREEN !important;
}

.stateTitle {
  text-align: left;
  white-space: nowrap;
  color: var(--main-colors-primary, $DARKPURPLE);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.secondary {
  // color: $Secondary;
  color: $PINK;
}

.yellow {
  // color: $Yellow
  color: $DARKYELLOW;
}

.green {
  color: $DARK_GREEN;
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.publisherName {
  // color: $BLACK;
  text-align: left;
  color: var(--gray-gray-10, $BLACK);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.expertRating {
  color: var(--main-colors-primary, $DARKPURPLE);
  font-family: Poppins !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.userRating {
  color: var(--main-colors-dark-green, $DARK_GREEN);
  font-family: Poppins !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.publisherTag {
  text-align: left;
  color: var(--main-colors-dark-green, $DARK_GREEN);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */

}

.hardCopyLabel {
  font-size: 16px;
  font-family: PoppinsMedium;
  text-align: center;
  // color: $PRIMARY;
}

@media screen and (max-height: 400px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(576, 20) !important;
    padding-left: calculatePixels(576, 24) !important;
    padding-right: calculatePixels(576, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(576, 249) !important;
    min-height: calculatePixels(576, 249) !important;
    padding-top: calculatePixels(576, 24) !important;
    padding-bottom: calculatePixels(576, 24) !important;
    gap: calculatePixels(576, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(576, 20) !important;
    max-height: calculatePixels(576, 198) !important;
    min-height: calculatePixels(576, 198) !important;
    gap: calculatePixels(576, 60) !important;
    padding: calculatePixels(576, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(576, 20) !important;
    max-height: calculatePixels(576, 83) !important;
    min-height: calculatePixels(576, 83) !important;
    padding: calculatePixels(576, 24) !important;
    gap: calculatePixels(576, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(576, 22) !important;
    width: calculatePixels(576, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(576, 24) !important;
    width: calculatePixels(576, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(576, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(576, 14) !important;
  }

  .starIcon {
    height: calculatePixels(576, 16) !important;
    width: calculatePixels(576, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(576, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(576, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(576, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(576, 18) !important;
  }

  .avatar {
    height: calculatePixels(576, 40) !important;
    width: calculatePixels(576, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(576, 16) !important;
  }

  .expertRating {
    font-size: calculatePixels(576, 16) !important;
  }

  .userRating {
    font-size: calculatePixels(576, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(576, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(576, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: column;
  }

  .btnContainer {
    height: calculatePixels(576, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(576, 32) !important;
    font-size: calculatePixels(576, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(576, 57) !important;
    max-height: calculatePixels(576, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(576, 32) !important;
    font-size: calculatePixels(576, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(576, 24) !important;
    width: calculatePixels(576, 24) !important;
  }
}

@media screen and (min-height: 400px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(576, 20) !important;
    padding-left: calculatePixels(576, 24) !important;
    padding-right: calculatePixels(576, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(576, 249) !important;
    min-height: calculatePixels(576, 249) !important;
    padding-top: calculatePixels(576, 24) !important;
    padding-bottom: calculatePixels(576, 24) !important;
    gap: calculatePixels(576, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(576, 20) !important;
    max-height: calculatePixels(576, 198) !important;
    min-height: calculatePixels(576, 198) !important;
    gap: calculatePixels(576, 60) !important;
    padding: calculatePixels(576, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(576, 20) !important;
    max-height: calculatePixels(576, 83) !important;
    min-height: calculatePixels(576, 83) !important;
    padding: calculatePixels(576, 24) !important;
    gap: calculatePixels(576, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(576, 22) !important;
    width: calculatePixels(576, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(576, 24) !important;
    width: calculatePixels(576, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(576, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(576, 14) !important;
  }

  .starIcon {
    height: calculatePixels(576, 16) !important;
    width: calculatePixels(576, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(576, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(576, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(576, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(576, 18) !important;
  }

  .avatar {
    height: calculatePixels(576, 40) !important;
    width: calculatePixels(576, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(576, 16) !important;
  }


  .expertRating {
    font-size: calculatePixels(576, 16) !important;
  }

  .userRating {
    font-size: calculatePixels(576, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(576, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(576, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: column;
  }

  .btnContainer {
    height: calculatePixels(576, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(576, 32) !important;
    font-size: calculatePixels(576, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(576, 57) !important;
    max-height: calculatePixels(576, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(576, 32) !important;
    font-size: calculatePixels(576, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(576, 24) !important;
    width: calculatePixels(576, 24) !important;
  }
}

@media screen and (min-height: 576px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(868, 20) !important;
    padding-left: calculatePixels(868, 24) !important;
    padding-right: calculatePixels(868, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(868, 249) !important;
    min-height: calculatePixels(868, 249) !important;
    padding-top: calculatePixels(868, 24) !important;
    padding-bottom: calculatePixels(868, 24) !important;
    gap: calculatePixels(868, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(868, 20) !important;
    max-height: calculatePixels(868, 198) !important;
    min-height: calculatePixels(868, 198) !important;
    gap: calculatePixels(868, 60) !important;
    padding: calculatePixels(868, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(868, 20) !important;
    max-height: calculatePixels(868, 83) !important;
    min-height: calculatePixels(868, 83) !important;
    padding: calculatePixels(868, 24) !important;
    gap: calculatePixels(868, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(868, 22) !important;
    width: calculatePixels(868, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(868, 24) !important;
    width: calculatePixels(868, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(868, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(868, 14) !important;
  }

  .starIcon {
    height: calculatePixels(868, 16) !important;
    width: calculatePixels(868, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(868, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(868, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(868, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(868, 18) !important;
  }

  .avatar {
    height: calculatePixels(868, 40) !important;
    width: calculatePixels(868, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(868, 16) !important;
  }

  .expertRating {
    font-size: calculatePixels(868, 16) !important;
  }

  .userRating {
    font-size: calculatePixels(868, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(868, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(868, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: row;
  }

  .btnContainer {
    height: calculatePixels(868, 57) !important;
    width: 50%;
    border-radius: calculatePixels(868, 32) !important;
    font-size: calculatePixels(868, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(868, 57) !important;
    max-height: calculatePixels(868, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(868, 32) !important;
    font-size: calculatePixels(868, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(868, 24) !important;
    width: calculatePixels(868, 24) !important;
  }
}

@media screen and (min-height: 700px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(992, 20) !important;
    padding-left: calculatePixels(992, 24) !important;
    padding-right: calculatePixels(992, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(992, 249) !important;
    min-height: calculatePixels(992, 249) !important;
    padding-top: calculatePixels(992, 24) !important;
    padding-bottom: calculatePixels(992, 24) !important;
    gap: calculatePixels(992, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(992, 20) !important;
    max-height: calculatePixels(992, 198) !important;
    min-height: calculatePixels(992, 198) !important;
    gap: calculatePixels(992, 60) !important;
    padding: calculatePixels(992, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(992, 20) !important;
    max-height: calculatePixels(992, 83) !important;
    min-height: calculatePixels(992, 83) !important;
    padding: calculatePixels(992, 24) !important;
    gap: calculatePixels(992, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(992, 22) !important;
    width: calculatePixels(992, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(992, 24) !important;
    width: calculatePixels(992, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(992, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(992, 14) !important;
  }

  .starIcon {
    height: calculatePixels(992, 16) !important;
    width: calculatePixels(992, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(992, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(992, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(992, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(992, 18) !important;
  }

  .avatar {
    height: calculatePixels(992, 40) !important;
    width: calculatePixels(992, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(992, 16) !important;
  }

  .expertRating {
    font-size: calculatePixels(992, 16) !important;
  }

  .userRating {
    font-size: calculatePixels(992, 16) !important;
  }



  .publisherTag {
    font-size: calculatePixels(992, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(992, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: row;
  }

  .btnContainer {
    height: calculatePixels(992, 57) !important;
    width: 50%;
    border-radius: calculatePixels(992, 32) !important;
    font-size: calculatePixels(992, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(992, 57) !important;
    max-height: calculatePixels(992, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(992, 32) !important;
    font-size: calculatePixels(992, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(992, 24) !important;
    width: calculatePixels(992, 24) !important;
  }
}

@media screen and (min-height: 768px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(1200, 20) !important;
    padding-left: calculatePixels(1200, 24) !important;
    padding-right: calculatePixels(1200, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(1200, 249) !important;
    min-height: calculatePixels(1200, 249) !important;
    padding-top: calculatePixels(1200, 24) !important;
    padding-bottom: calculatePixels(1200, 24) !important;
    gap: calculatePixels(1200, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(1200, 20) !important;
    max-height: calculatePixels(1200, 198) !important;
    min-height: calculatePixels(1200, 198) !important;
    gap: calculatePixels(1200, 60) !important;
    padding: calculatePixels(1200, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(1200, 20) !important;
    max-height: calculatePixels(1200, 83) !important;
    min-height: calculatePixels(1200, 83) !important;
    padding: calculatePixels(1200, 24) !important;
    gap: calculatePixels(1200, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(1200, 22) !important;
    width: calculatePixels(1200, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(1200, 24) !important;
    width: calculatePixels(1200, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(1200, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(1200, 14) !important;
  }

  .starIcon {
    height: calculatePixels(1200, 16) !important;
    width: calculatePixels(1200, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(1200, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(1200, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(1200, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(1200, 18) !important;
  }

  .avatar {
    height: calculatePixels(1200, 40) !important;
    width: calculatePixels(1200, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(1200, 16) !important;
  }

  .expertRating {
    font-size: calculatePixels(1200, 16) !important;
  }

  .userRating {
    font-size: calculatePixels(1200, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(1200, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(1200, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: row;
  }

  .btnContainer {
    height: calculatePixels(1200, 57) !important;
    width: 50%;
    border-radius: calculatePixels(1200, 32) !important;
    font-size: calculatePixels(1200, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(1200, 57) !important;
    max-height: calculatePixels(1200, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(1200, 32) !important;
    font-size: calculatePixels(1200, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(1200, 24) !important;
    width: calculatePixels(1200, 24) !important;
  }
}

@media screen and (min-height: 868px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(1250, 20) !important;
    padding-left: calculatePixels(1250, 24) !important;
    padding-right: calculatePixels(1250, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(1250, 249) !important;
    min-height: calculatePixels(1250, 249) !important;
    padding-top: calculatePixels(1250, 24) !important;
    padding-bottom: calculatePixels(1250, 24) !important;
    gap: calculatePixels(1250, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(1250, 20) !important;
    max-height: calculatePixels(1250, 198) !important;
    min-height: calculatePixels(1250, 198) !important;
    gap: calculatePixels(1250, 60) !important;
    padding: calculatePixels(1250, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(1250, 20) !important;
    max-height: calculatePixels(1250, 83) !important;
    min-height: calculatePixels(1250, 83) !important;
    padding: calculatePixels(1250, 24) !important;
    gap: calculatePixels(1250, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(1250, 22) !important;
    width: calculatePixels(1250, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(1250, 24) !important;
    width: calculatePixels(1250, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(1250, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(1250, 14) !important;
  }

  .starIcon {
    height: calculatePixels(1250, 16) !important;
    width: calculatePixels(1250, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(1250, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(1250, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(1250, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(1250, 18) !important;
  }

  .avatar {
    height: calculatePixels(1250, 40) !important;
    width: calculatePixels(1250, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(1250, 16) !important;
  }

  .expertRating {
    font-size: calculatePixels(1250, 16) !important;
  }

  .userRating {
    font-size: calculatePixels(1250, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(1250, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(1250, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: row;
  }

  .btnContainer {
    height: calculatePixels(1250, 57) !important;
    width: 50%;
    border-radius: calculatePixels(1250, 32) !important;
    font-size: calculatePixels(1250, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(1250, 57) !important;
    max-height: calculatePixels(1250, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(1250, 32) !important;
    font-size: calculatePixels(1250, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(1250, 24) !important;
    width: calculatePixels(1250, 24) !important;
  }
}

@media screen and (min-height: 950px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(1400, 20) !important;
    padding-left: calculatePixels(1400, 24) !important;
    padding-right: calculatePixels(1400, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(1400, 249) !important;
    min-height: calculatePixels(1400, 249) !important;
    padding-top: calculatePixels(1400, 24) !important;
    padding-bottom: calculatePixels(1400, 24) !important;
    gap: calculatePixels(1400, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(1400, 20) !important;
    max-height: calculatePixels(1400, 198) !important;
    min-height: calculatePixels(1400, 198) !important;
    gap: calculatePixels(1400, 60) !important;
    padding: calculatePixels(1400, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(1400, 20) !important;
    max-height: calculatePixels(1400, 83) !important;
    min-height: calculatePixels(1400, 83) !important;
    padding: calculatePixels(1400, 24) !important;
    gap: calculatePixels(1400, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(1400, 22) !important;
    width: calculatePixels(1400, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(1400, 24) !important;
    width: calculatePixels(1400, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(1400, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(1400, 14) !important;
  }

  .starIcon {
    height: calculatePixels(1400, 16) !important;
    width: calculatePixels(1400, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(1400, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(1400, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(1400, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(1400, 18) !important;
  }

  .avatar {
    height: calculatePixels(1400, 40) !important;
    width: calculatePixels(1400, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(1400, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(1400, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(1400, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: row;
  }

  .btnContainer {
    height: calculatePixels(1400, 57) !important;
    width: 50%;
    border-radius: calculatePixels(1400, 32) !important;
    font-size: calculatePixels(1400, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(1400, 57) !important;
    max-height: calculatePixels(1400, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(1400, 32) !important;
    font-size: calculatePixels(1400, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(1400, 24) !important;
    width: calculatePixels(1400, 24) !important;
  }
}

@media (max-width: 576px) and (min-height: 868px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(576, 20) !important;
    padding-left: calculatePixels(576, 24) !important;
    padding-right: calculatePixels(576, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(576, 249) !important;
    min-height: calculatePixels(576, 249) !important;
    padding-top: calculatePixels(576, 24) !important;
    padding-bottom: calculatePixels(576, 24) !important;
    gap: calculatePixels(576, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(576, 20) !important;
    max-height: calculatePixels(576, 198) !important;
    min-height: calculatePixels(576, 198) !important;
    gap: calculatePixels(576, 60) !important;
    padding: calculatePixels(576, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(576, 20) !important;
    max-height: calculatePixels(576, 83) !important;
    min-height: calculatePixels(576, 83) !important;
    padding: calculatePixels(576, 24) !important;
    gap: calculatePixels(576, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(576, 22) !important;
    width: calculatePixels(576, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(576, 24) !important;
    width: calculatePixels(576, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(576, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(576, 14) !important;
  }

  .starIcon {
    height: calculatePixels(576, 16) !important;
    width: calculatePixels(576, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(576, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(576, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(576, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(576, 18) !important;
  }

  .avatar {
    height: calculatePixels(576, 40) !important;
    width: calculatePixels(576, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(576, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(576, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(576, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: column;
  }

  .btnContainer {
    height: calculatePixels(576, 57) !important;
    width: 100%;
    border-radius: calculatePixels(576, 32) !important;
    font-size: calculatePixels(576, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(576, 57) !important;
    max-height: calculatePixels(576, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(576, 32) !important;
    font-size: calculatePixels(576, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(576, 24) !important;
    width: calculatePixels(576, 24) !important;
  }
}

@media (min-width: 576px) and (min-height: 868px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(576, 20) !important;
    padding-left: calculatePixels(576, 24) !important;
    padding-right: calculatePixels(576, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(576, 249) !important;
    min-height: calculatePixels(576, 249) !important;
    padding-top: calculatePixels(576, 24) !important;
    padding-bottom: calculatePixels(576, 24) !important;
    gap: calculatePixels(576, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(576, 20) !important;
    max-height: calculatePixels(576, 198) !important;
    min-height: calculatePixels(576, 198) !important;
    gap: calculatePixels(576, 60) !important;
    padding: calculatePixels(576, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(576, 20) !important;
    max-height: calculatePixels(576, 83) !important;
    min-height: calculatePixels(576, 83) !important;
    padding: calculatePixels(576, 24) !important;
    gap: calculatePixels(576, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(576, 22) !important;
    width: calculatePixels(576, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(576, 24) !important;
    width: calculatePixels(576, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(576, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(576, 14) !important;
  }

  .starIcon {
    height: calculatePixels(576, 16) !important;
    width: calculatePixels(576, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(576, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(576, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(576, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(576, 18) !important;
  }

  .avatar {
    height: calculatePixels(576, 40) !important;
    width: calculatePixels(576, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(576, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(576, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(576, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: column;
  }

  .btnContainer {
    height: calculatePixels(576, 57) !important;
    width: 100%;
    border-radius: calculatePixels(576, 32) !important;
    font-size: calculatePixels(576, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(576, 57) !important;
    max-height: calculatePixels(576, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(576, 32) !important;
    font-size: calculatePixels(576, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(576, 24) !important;
    width: calculatePixels(576, 24) !important;
  }
}

@media (min-width: 768px) and (min-height: 868px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(768, 20) !important;
    padding-left: calculatePixels(768, 24) !important;
    padding-right: calculatePixels(768, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(768, 249) !important;
    min-height: calculatePixels(768, 249) !important;
    padding-top: calculatePixels(768, 24) !important;
    padding-bottom: calculatePixels(768, 24) !important;
    gap: calculatePixels(768, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(768, 20) !important;
    max-height: calculatePixels(768, 198) !important;
    min-height: calculatePixels(768, 198) !important;
    gap: calculatePixels(768, 60) !important;
    padding: calculatePixels(768, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(768, 20) !important;
    max-height: calculatePixels(768, 83) !important;
    min-height: calculatePixels(768, 83) !important;
    padding: calculatePixels(768, 24) !important;
    gap: calculatePixels(768, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(768, 22) !important;
    width: calculatePixels(768, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(768, 24) !important;
    width: calculatePixels(768, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(768, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(768, 14) !important;
  }

  .starIcon {
    height: calculatePixels(768, 16) !important;
    width: calculatePixels(768, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(768, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(768, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(768, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(768, 18) !important;
  }

  .avatar {
    height: calculatePixels(768, 40) !important;
    width: calculatePixels(768, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(768, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(768, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(768, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: column;
  }

  .btnContainer {
    height: calculatePixels(768, 57) !important;
    width: 100%;
    border-radius: calculatePixels(768, 32) !important;
    font-size: calculatePixels(768, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(768, 57) !important;
    max-height: calculatePixels(768, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(768, 32) !important;
    font-size: calculatePixels(768, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(768, 24) !important;
    width: calculatePixels(768, 24) !important;
  }
}

@media (min-width: 992px) and (min-height: 868px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(992, 20) !important;
    padding-left: calculatePixels(992, 24) !important;
    padding-right: calculatePixels(992, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(992, 249) !important;
    min-height: calculatePixels(992, 249) !important;
    padding-top: calculatePixels(992, 24) !important;
    padding-bottom: calculatePixels(992, 24) !important;
    gap: calculatePixels(992, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(992, 20) !important;
    max-height: calculatePixels(992, 198) !important;
    min-height: calculatePixels(992, 198) !important;
    gap: calculatePixels(992, 60) !important;
    padding: calculatePixels(992, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(992, 20) !important;
    max-height: calculatePixels(992, 83) !important;
    min-height: calculatePixels(992, 83) !important;
    padding: calculatePixels(992, 24) !important;
    gap: calculatePixels(992, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(992, 22) !important;
    width: calculatePixels(992, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(992, 24) !important;
    width: calculatePixels(992, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(992, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(992, 14) !important;
  }

  .starIcon {
    height: calculatePixels(992, 16) !important;
    width: calculatePixels(992, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(992, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(992, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(992, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(992, 18) !important;
  }

  .avatar {
    height: calculatePixels(992, 40) !important;
    width: calculatePixels(992, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(992, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(992, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(992, 16) !important;
  }

  .btnContainer {
    height: calculatePixels(992, 57) !important;
    width: 50%;
    border-radius: calculatePixels(992, 32) !important;
    font-size: calculatePixels(992, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(992, 57) !important;
    max-height: calculatePixels(992, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(992, 32) !important;
    font-size: calculatePixels(992, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: row;
  }

  .btnIcon {
    height: calculatePixels(992, 24) !important;
    width: calculatePixels(992, 24) !important;
  }
}

@media (min-width: 1200px) and (min-height: 868px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(1200, 20) !important;
    padding-left: calculatePixels(1200, 24) !important;
    padding-right: calculatePixels(1200, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(1200, 249) !important;
    min-height: calculatePixels(1200, 249) !important;
    padding-top: calculatePixels(1200, 24) !important;
    padding-bottom: calculatePixels(1200, 24) !important;
    gap: calculatePixels(1200, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(1200, 20) !important;
    max-height: calculatePixels(1200, 198) !important;
    min-height: calculatePixels(1200, 198) !important;
    gap: calculatePixels(1200, 60) !important;
    padding: calculatePixels(1200, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(1200, 20) !important;
    max-height: calculatePixels(1200, 83) !important;
    min-height: calculatePixels(1200, 83) !important;
    padding: calculatePixels(1200, 24) !important;
    gap: calculatePixels(1200, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(1200, 22) !important;
    width: calculatePixels(1200, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(1200, 24) !important;
    width: calculatePixels(1200, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(1200, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(1200, 14) !important;
  }

  .starIcon {
    height: calculatePixels(1200, 16) !important;
    width: calculatePixels(1200, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(1200, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(1200, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(1200, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(1200, 18) !important;
  }

  .avatar {
    height: calculatePixels(1200, 40) !important;
    width: calculatePixels(1200, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(1200, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(1200, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(1200, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: row;
  }

  .btnContainer {
    height: calculatePixels(1200, 57) !important;
    width: 50%;
    border-radius: calculatePixels(1200, 32) !important;
    font-size: calculatePixels(1200, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(1200, 57) !important;
    max-height: calculatePixels(1200, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(1200, 32) !important;
    font-size: calculatePixels(1200, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(1200, 24) !important;
    width: calculatePixels(1200, 24) !important;
  }
}

@media (min-width: 1400px) and (min-height: 868px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(1400, 20) !important;
    padding-left: calculatePixels(1400, 24) !important;
    padding-right: calculatePixels(1400, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(1400, 249) !important;
    min-height: calculatePixels(1400, 249) !important;
    padding-top: calculatePixels(1400, 24) !important;
    padding-bottom: calculatePixels(1400, 24) !important;
    gap: calculatePixels(1400, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(1400, 20) !important;
    max-height: calculatePixels(1400, 198) !important;
    min-height: calculatePixels(1400, 198) !important;
    gap: calculatePixels(1400, 60) !important;
    padding: calculatePixels(1400, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(1400, 20) !important;
    max-height: calculatePixels(1400, 83) !important;
    min-height: calculatePixels(1400, 83) !important;
    padding: calculatePixels(1400, 24) !important;
    gap: calculatePixels(1400, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(1400, 22) !important;
    width: calculatePixels(1400, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(1400, 24) !important;
    width: calculatePixels(1400, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(1400, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(1400, 14) !important;
  }

  .starIcon {
    height: calculatePixels(1400, 16) !important;
    width: calculatePixels(1400, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(1400, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(1400, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(1400, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(1400, 18) !important;
  }

  .avatar {
    height: calculatePixels(1400, 40) !important;
    width: calculatePixels(1400, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(1400, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(1400, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(1400, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: row;
  }

  .btnContainer {
    height: calculatePixels(1400, 57) !important;
    width: 50%;
    border-radius: calculatePixels(1400, 32) !important;
    font-size: calculatePixels(1400, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(1400, 57) !important;
    max-height: calculatePixels(1400, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(1400, 32) !important;
    font-size: calculatePixels(1400, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(1400, 24) !important;
    width: calculatePixels(1400, 24) !important;
  }
}

@media (min-width: 1600px) and (min-height: 868px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(1400, 20) !important;
    padding-left: calculatePixels(1400, 24) !important;
    padding-right: calculatePixels(1400, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(1400, 249) !important;
    min-height: calculatePixels(1400, 249) !important;
    padding-top: calculatePixels(1400, 24) !important;
    padding-bottom: calculatePixels(1400, 24) !important;
    gap: calculatePixels(1400, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(1400, 20) !important;
    max-height: calculatePixels(1400, 198) !important;
    min-height: calculatePixels(1400, 198) !important;
    gap: calculatePixels(1400, 60) !important;
    padding: calculatePixels(1400, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(1400, 20) !important;
    max-height: calculatePixels(1400, 83) !important;
    min-height: calculatePixels(1400, 83) !important;
    padding: calculatePixels(1400, 24) !important;
    gap: calculatePixels(1400, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(1400, 22) !important;
    width: calculatePixels(1400, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(1400, 24) !important;
    width: calculatePixels(1400, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(1400, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(1400, 14) !important;
  }

  .starIcon {
    height: calculatePixels(1400, 16) !important;
    width: calculatePixels(1400, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(1400, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(1400, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(1400, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(1400, 18) !important;
  }

  .avatar {
    height: calculatePixels(1400, 40) !important;
    width: calculatePixels(1400, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(1400, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(1400, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(1400, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: row;
  }

  .btnContainer {
    height: calculatePixels(1400, 57) !important;
    width: 50%;
    border-radius: calculatePixels(1400, 32) !important;
    font-size: calculatePixels(1400, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(1400, 57) !important;
    max-height: calculatePixels(1400, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(1400, 32) !important;
    font-size: calculatePixels(1400, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(1400, 24) !important;
    width: calculatePixels(1400, 24) !important;
  }
}

@media (min-width: 1800px) and (min-height: 868px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(1400, 20) !important;
    padding-left: calculatePixels(1400, 24) !important;
    padding-right: calculatePixels(1400, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(1400, 249) !important;
    min-height: calculatePixels(1400, 249) !important;
    padding-top: calculatePixels(1400, 24) !important;
    padding-bottom: calculatePixels(1400, 24) !important;
    gap: calculatePixels(1400, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(1400, 20) !important;
    max-height: calculatePixels(1400, 198) !important;
    min-height: calculatePixels(1400, 198) !important;
    gap: calculatePixels(1400, 60) !important;
    padding: calculatePixels(1400, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(1400, 20) !important;
    max-height: calculatePixels(1400, 83) !important;
    min-height: calculatePixels(1400, 83) !important;
    padding: calculatePixels(1400, 24) !important;
    gap: calculatePixels(1400, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(1400, 22) !important;
    width: calculatePixels(1400, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(1400, 24) !important;
    width: calculatePixels(1400, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(1400, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(1400, 14) !important;
  }

  .starIcon {
    height: calculatePixels(1400, 16) !important;
    width: calculatePixels(1400, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(1400, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(1400, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(1400, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(1400, 18) !important;
  }

  .avatar {
    height: calculatePixels(1400, 40) !important;
    width: calculatePixels(1400, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(1400, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(1400, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(1400, 16) !important;
  }

  .btnContainer {
    height: calculatePixels(1400, 57) !important;
    width: 50%;
    border-radius: calculatePixels(1400, 32) !important;
    font-size: calculatePixels(1400, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(1400, 57) !important;
    max-height: calculatePixels(1400, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(1400, 32) !important;
    font-size: calculatePixels(1400, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(1400, 24) !important;
    width: calculatePixels(1400, 24) !important;
  }
}

@media (min-width: 2000px) and (min-height: 868px) {
  .bookDetailWrapper {
    border-radius: calculatePixels(1400, 20) !important;
    padding-left: calculatePixels(1400, 24) !important;
    padding-right: calculatePixels(1400, 24) !important;
  }

  .bookDetailContainer {
    max-height: calculatePixels(1400, 249) !important;
    min-height: calculatePixels(1400, 249) !important;
    padding-top: calculatePixels(1400, 24) !important;
    padding-bottom: calculatePixels(1400, 24) !important;
    gap: calculatePixels(1400, 10) !important;
  }

  .bookStatsInfoContainer {
    border-radius: calculatePixels(1400, 20) !important;
    max-height: calculatePixels(1400, 198) !important;
    min-height: calculatePixels(1400, 198) !important;
    gap: calculatePixels(1400, 60) !important;
    padding: calculatePixels(1400, 24) !important;
  }

  .publisherInfoContainer {
    border-radius: calculatePixels(1400, 20) !important;
    max-height: calculatePixels(1400, 83) !important;
    min-height: calculatePixels(1400, 83) !important;
    padding: calculatePixels(1400, 24) !important;
    gap: calculatePixels(1400, 10) !important;
  }

  .flagIcon {
    height: calculatePixels(1400, 22) !important;
    width: calculatePixels(1400, 22) !important;
  }

  .heartIcon {
    height: calculatePixels(1400, 24) !important;
    width: calculatePixels(1400, 24) !important;
  }

  .bookTitle {
    font-size: calculatePixels(1400, 24) !important;
  }

  .authorName {
    font-size: calculatePixels(1400, 14) !important;
  }

  .starIcon {
    height: calculatePixels(1400, 16) !important;
    width: calculatePixels(1400, 16) !important;
  }

  .ratingLabel {
    font-size: calculatePixels(1400, 12) !important;
  }

  .ratingCount {
    font-size: calculatePixels(1400, 12) !important;
  }

  .descriptionLabel {
    font-size: calculatePixels(1400, 16) !important;
  }

  .stateTitle {
    font-size: calculatePixels(1400, 18) !important;
  }

  .avatar {
    height: calculatePixels(1400, 40) !important;
    width: calculatePixels(1400, 40) !important;
  }

  .publisherName {
    font-size: calculatePixels(1400, 16) !important;
  }

  .publisherTag {
    font-size: calculatePixels(1400, 12) !important;
  }

  .hardCopyLabel {
    font-size: calculatePixels(1400, 16) !important;
  }

  .btnGrupContainer {
    flex-direction: row;
  }

  .btnContainer {
    height: calculatePixels(1400, 57) !important;
    width: 50%;
    border-radius: calculatePixels(1400, 32) !important;
    font-size: calculatePixels(1400, 16) !important;
  }

  .assignBtnContainer {
    min-height: calculatePixels(1400, 57) !important;
    max-height: calculatePixels(1400, 57) !important;
    width: 100% !important;
    border-radius: calculatePixels(1400, 32) !important;
    font-size: calculatePixels(1400, 16) !important;
  }

  .btnIcon {
    height: calculatePixels(1400, 24) !important;
    width: calculatePixels(1400, 24) !important;
  }
}