@import "shared/utils/colors.scss";

.btnCustom {
  width: auto;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 10px;
}

.topGraphContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topLevelGrid {
  padding-left: 17px;
  margin-top: 0px !important;
}

.chartGrid {
  padding-right: 20px !important;
}

.selectFilter {
  padding: 0.5rem;
  border: 1px solid #f1f1f5;
  border-radius: 5px;
}

.selectFilter:focus-visible {
  border: 1px solid #fff3ff;
  outline: 1px solid #fff3ff;
}

.selectFilter {
  padding: 0.5rem;
  border: 1px solid #f1f1f5;
  border-radius: 5px;
}

.selectFilter:focus-visible {
  border: 1px solid #fff3ff;
  outline: 1px solid #fff3ff;
}

.message {
  color: white !important;
}

.selectElement {
  div {
    background-color: $DARKPURPLE;
  }
  margin-left: 5px;
}

.dateLabel {
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
  font-family: Poppins;
  color: $TEXTGREY;
  text-align: left;
}

.secondary {
  background-color: $PINK;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin-left: 5px;
}

.primary {
  background-color: $DARK_GREEN;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin-left: 5px;
}

.graphLabel {
  font-size: 10px;
  font-family: Poppins;
  color: #0f1106;
  text-align: left;
  margin-left: 5px;
}

.cardContent {
  padding: 30px !important;
  padding-bottom: 30px !important;
}

.datePickerSales {
  display: none;
}

.title {
  color: var(--gray-900, $BLACK);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  // margin-bottom: 20px;
  //   width: 100%;
}

.card {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
}

.cardContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.rangeInputWrapper input {
  border: none;
  padding: 0;
  cursor: pointer;
}

.rangeInputWrapper > div > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.rangeInputWrapper svg {
  position: relative !important;
  padding: 0 !important;
  fill: #9a469b;
}

.filterWrapper {
  color: red;
}

.filterWrapper .message {
  margin: 0;
  padding: 0;
  color: gray;
}

.filterWrapper div div {
  padding: 8px;
}

.filterWrapper > div:hover {
  border: none !important;
  outline: none !important;
}

.filterWrapper:focus-visible,
.filterWrapper > div:focus-visible,
.filterWrapper > div > div:focus-visible {
  border: none !important;
  outline: none !important;
}

.filterWrapper svg {
  fill: white !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.filtersContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 0.2rem;
}

.ageSelector:hover {
  border: none;
  outline: none;
}

.checkbox {
  color: $DARKPURPLE !important;
}

@media screen and (min-width: 768px) {
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .filtersContainer {
    align-items: center;
  }
}

@media screen and (min-width: 500px) {
  .cardContentWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .rangeInputWrapper > div > div {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 900px) {
  .chartGrid {
    padding-right: 0px !important;
  }
}
