@import "shared/utils/colors.scss";

.card {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
}

.titleHeading {
  font-weight: 700;
  font-size: 16px;
}

.title {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  gap: 1rem;
  padding: 18px 0;
  border-bottom: 1px solid #f1f1f5;
  height: auto;
}

.title:last-child {
  border-bottom: none;
}

.cardContent {
  height: 260px;
}

.genreTitle {
  font-weight: 400;
  font-size: 18px;
  color: #868e96;
}

.genreCount {
  font-weight: 800;
  font-size: 20px;
  color: #ef437b;
}

.noData svg {
  width: 5rem;
  height: 5rem;
}

.noData h5 {
  margin: 0;
}

@media screen and (max-width: 990px) {
  .title:last-child {
    border-bottom: none;
  }
}
