@import "shared/utils/colors.scss";

.card {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
}

.tab {
  color: $TEXTGREY !important;
  font-family: Poppins !important;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.topMostContainer {
  border: 1px solid $GREY2;
  padding: 40px;
  border-radius: 16px;
  margin-bottom: 40px;
  margin: auto;
  margin-bottom: 3rem;
}

.heading {
  color: var(--gray-900, black);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.input:focus-visible {
  outline: none;
}

.inputsWrapper input {
  height: 55px;
}
.inputContainer {
  display: flex;
  box-sizing: border-box;
  margin-top: 5px;
  gap: 1rem;
}

.inputSingleContainer {
  width: 100%;
}

.inputContainerButton {
  display: flex;
  justify-content: center;
}

.btnCustom {
  width: 150px;
}

.titleInput {
  color: var(--gray-gray-10, $BLACK);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;

  span {
    color: $DARKPURPLE;
  }
}

.errorText {
  height: 20px;
  font-size: 12px;
  color: red;
  display: flex;
  justify-content: start;
}

.iconContainer {
  position: absolute;
  top: 11px;
  right: 29px;
  color: $TEXTGREY;
  cursor: pointer;
}

.inputContainerButton {
  justify-content: end;
}

@media screen and (max-width: 600px) {
  .topMostContainer {
    padding: 20px;
  }

  .inputContainer {
    display: block;
  }
  .inputContainerButton {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 990px) {
  .inputsWrapper {
    display: grid;
    grid-template-columns: 1fr;
  }
  .topMostContainer {
    max-width: 600px;
    margin: auto;
    margin-bottom: 2rem;
  }
}
