@import "shared/utils/colors.scss";
.btnWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btnWrapper button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 1rem 20px;
  border-radius: 4px;
  background-color: $DARKPURPLE;
  height: 45px;
  font-size: 1rem;
  font-family: "poppins";
  color: white;
  cursor: pointer;
}

.content {
  margin-bottom: 2rem;
}

.content p {
  margin: 0;
  margin-top: 0.5rem;
  font-family: "poppins";
  font-size: 1rem;
}

.icon svg {
  width: 2rem;
  height: 2rem;
}
