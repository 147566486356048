@import "../../../pages/membership/style.module.scss";

.crossIcon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}
.topBalanceWrapper {
  flex-direction: row;
  column-gap: 0.5rem;
  width: 100% !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.topBalanceWrapper {
  div {
    flex-basis: 48% !important;
    max-width: 100% !important;
  }
}

.topUpBalanceBtnWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  button {
    width: max-content !important;
    padding: 10px 40px;
  }
}

@media screen and (max-width: 500px) {
  .topBalanceWrapper {
    flex-direction: column;
    row-gap: 0.5rem;
  }
  .topUpBalanceBtnWrapper {
    justify-content: center;
  }
}

.title {
  font-size: 24px;
  line-height: 36px;
  color: $DARKPURPLE;
  font-family: Poppins;
  font-weight: 600;
}

.subLabel {
  font-size: 18px;
  line-height: 27px;
  color: $DARKPURPLE;
  font-family: Poppins;
  font-weight: 600;
}

.container {
  background: #fbfbfb;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 24px 18px;
}

.addBtn {
  width: 160px;
  height: 48px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: 32px;
  background-color: $DARKPURPLE;
  color: #fbfbfb;
}

.cancelBtn {
  width: 160px;
  height: 48px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #868e96;
  border: 1px solid #868e96;
  border-radius: 32px;
  background-color: white;
}

.btnContainer {
  display: flex;
  gap: 12px;
}
