@import "shared/utils/colors.scss";

.dialogTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crossIcon {
  cursor: pointer;
}

.table {
  box-shadow: none !important;
  border-radius: 0px !important;
}

.table::-webkit-scrollbar {
  display: none;
}

.tableHead {
  th {
    border-top: 1px solid $GREY2;
    background-color: #f9fafb;
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 12px 16px;
  }
}

.tableRow {
  td {
    min-width: 180px !important;
  }
}

.container {
  box-shadow: none !important;
}

h4 {
  margin: 0.5rem 0;
}

.iconWrapper {
  cursor: pointer;

  svg {
    color: #868E96;
  }
}