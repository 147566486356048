@import "shared/utils/colors.scss";


.table {
    box-shadow: none !important;
    border-radius: 0px !important;
}

.table::-webkit-scrollbar {
    display: none;
}


.tableHead {
    th {
        border-top: 1px solid $GREY2;
        background-color: #f9fafb;
        color: var(--gray-gray-07, $TEXTGREY);
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        padding: 12px 16px;
    }
}

.tableRow {
    td {
        min-width: 180px !important;
    }
}

.container {
    box-shadow: none !important;
}

.topLevelContainer {
    background-color: white;
    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    //   0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
    border-radius: 4px;
    margin-top: 2rem;
}

.topHeadContainer {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 20px;
}

.tableHeading {
    color: var(--gray-900, black);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.tableSubHeading {
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.filterContainer {
    display: flex;
    align-items: center;
}

.gridContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 10px;
}

.searchIcon {
    position: absolute;
    top: 13px;
    left: 0;
    padding-left: 10px;
}

.input {
    color: var(--gray-gray-07, $TEXTGREY);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border: 1px solid var(--gray-gray-06, $TEXTGREY);
    border-radius: 32px;
    padding: 10px 14px;
    padding-left: 40px;
}

.inputCategory:focus-visible {
    outline: none !important;
}

.iconWrapper {
    cursor: pointer;
    border: none;
    background-color: transparent;

    svg {
        color: $TEXTGREY;
        ;
    }
}

.createBtn {
    padding: 14px 25px;
    margin-left: 10px;
    width: auto !important;
    white-space: pre;
}

.rejected {
    color: $DARKPURPLE;
    // padding: 0.5rem;
    border-radius: 4px;
}

.active {
    color: $GREENBLUE;
}

.fullName {
    max-width: 230px;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
}

.pending {
    color: $DARKYELLOW;
}


input::placeholder {
    font-size: 12px;
    color: $TEXTGREY;
}

.email {
    color: $TEXTGREY;
    font-size: 12px;
}

.input {
    padding-block: 14px !important;
}

.accountInfo {
    display: flex;
    flex-direction: column;
    // gap: .1rem;
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .createBtn {
        padding: 10px 16px;
        white-space: pre;
    }
}


@media screen and (max-width:768px) {
    .filterBox {
        flex-wrap: wrap !important;
    }

    .filterBox button {
        margin-top: 0 !important;
    }

    input {
        margin-bottom: .5rem;
    }

    .createBtn {
        font-size: 14px;
        padding: 12px 16px;
        margin-left: 0;
    }

    .topHeadContainer {
        display: block;
    }

    .filterContainer {
        display: block;
    }

    .input {
        width: 100%;
    }

    .gridContainer {
        margin-right: 0px;
    }

    .filterStyles {
        width: 100%;
    }

    .tableSubHeading {
        margin-bottom: 30px;
    }

    .createBtn {
        margin-top: .5rem;
        margin-left: 0;
    }
}