@import "shared/utils/colors.scss";

.cardWrapper {
  // box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  background-color: white;
  border-radius: 16px;
  border: 1px solid $BORDERGREY;
}
.userProfilewrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  //   border-radius: 5px;
}

.userInfoWrapper {
  font-family: poppins;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.userProfileImageWrapper {
  margin: 0;
  width: 3rem;
  height: 3rem;
}

.profileImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.deactiveBtn {
  border: 1px solid $DARKPURPLE;
  outline: none;
  color: $DARKPURPLE;
  font-weight: bold;
  background-color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.userInfo .name {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.userInfo .type {
  font-size: 13px;
  color: gray;
}

.infoDetails {
  background-color: white;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.skeletonWrapper {
  display: flex;
  gap: 1rem;
}

.line {
  height: 1px;
  // width: 100%;
  margin: 0 1rem;
  background-color: #c3cfd9;
  padding: 0 1rem;
}

.outerFlex {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.innerFlex {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  flex-wrap: wrap;
}

.detailsWrapper {
  padding: 1rem;
}

.heading {
  color: $DARKPURPLE;
  font-weight: bold;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.value {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "poppins";
}

.bankName {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-gray-07, #868e96);
}
@media screen and (max-width: 500px) {
  .userProfilewrapper {
    gap: 0.5rem;
  }
}
