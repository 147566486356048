.iconWrapper {
    cursor: pointer;
    display: inline-block;
    text-align: end;
    width: 100%;
}

.checkIconWrapper {
    display: grid;
    place-items: center;
    margin-bottom: 1rem;
}

.checkIconWrapper svg {
    width: 8rem;
    height: 8rem;
}

.heading {
    margin: 0;
    text-align: center;
}

.content {
    font-size: 16px;
}

@media screen and (max-width:500px) {
    .heading {
        font-size: 18px;
    }

    .checkIconWrapper svg {
        width: 6rem;
        height: 6rem;
    }

    .content {
        font-size: 14px;
    }

}