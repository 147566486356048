@import "shared/utils/colors.scss";

.table {
    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
    box-shadow: none !important;
    // margin-top: 35px;
    border-radius: 0px !important;
    margin-top: 20px;
}

.table::-webkit-scrollbar {
    display: none;
}

.btnContainer {
    margin: 20px 0px;
    display: flex;
    justify-content: end;
}

.btnCustom {
    width: auto;
    border: 1.5px solid $DARKPURPLE;
    margin-left: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.btnPlayCustom {
    width: 140px;
    border: 1.5px solid $DARKPURPLE;
    padding-left: 20px;
    padding-right: 20px;
}

.btnInsideOut {
    background: transparent !important;
    color: black;
    width: auto;
    border: 1.5px solid $DARKPURPLE;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15px;

}

.textarea {
    width: 100%;
    height: 130px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid var(--gray-gray-06, $GREY);
    border-radius: 20px;
    resize: none;
    margin-top: 10px;
    font-family: Poppins;
}
.tableHead {
    th {
        border-top: 1px solid $GREY2;
        background-color: #F9FAFB;
        color: var(--gray-gray-07, $TEXTGREY);
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        padding: 12px 16px;

    }
}

.headingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    color: var(--gray-gray-10, $BLACK);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.dot {
    color: $PINK;
    font-size: 40px;
    margin-right: 5px;
}

.noUserCard {
    display: flex;
    justify-content: center;
    padding: 40px;
    flex-direction: column;
    align-items: center;
}


.time {
    color: var(--gray-gray-08, $BLACK);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.repliedText {
    color: var(--gray-gray-08, $BLACK);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background-color: $LIGHTYELLOW2;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
}
