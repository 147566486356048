@import "shared/utils/colors.scss";

.container > div:first-child {
  background-color: white;
}

.container > div:first-child > div:first-child {
  border: 1px solid rgba(224, 224, 224, 1);
}

.container > div:first-child > div:last-child {
  border: 1px solid rgba(224, 224, 224, 1);
}

.btnWrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
}

.btnWrapper {
  button {
    font-family: "poppins";
    border-radius: 38px;
    font-size: 1rem;
    padding: 10px 40px;
    border: none;
    cursor: pointer;
  }

  .cancelBtn {
    border: 1px solid $DARKPURPLE;
    background-color: white;
  }
  .saveBtn {
    background-color: $DARKPURPLE;
    color: white;
  }
}

button:disabled {
  cursor: not-allowed;
}

@media screen and (max-width: 500px) {
  .btnWrapper {
    justify-content: flex-end;
  }
}
