@import "shared/utils/colors.scss";

.card {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
}

.bookCover {
  height: 50px;
  width: 45px;
  object-fit: fill;
}

.titleHeading {
  font-weight: 700;
  font-size: 16px;
}

.title {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.singleContainer {
  display: flex;
  gap: 1rem;
  margin-bottom: 10px;
  margin-top: 20px;
  align-items: center;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 21px;
}

.singleContainer:last-child {
  border-bottom: none;
}

.thumbnailWrapper {
  width: 60px;
  height: 67px;
}

.thumbnailWrapper img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 5px;
  //   border: 1px solid #fff2ff;
}

.titleLabel {
  font-weight: 500;
}
.count {
  color: #868e96;
}

.headingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectFilter {
  padding: 0.5rem;
  border: 1px solid #f1f1f5;
  border-radius: 5px;
}

.selectFilter:focus-visible {
  border: 1px solid #fff3ff;
  outline: 1px solid #fff3ff;
}

.noData svg {
  width: 5rem;
  height: 5rem;
}

.noData h5 {
  margin: 0;
}
