.imgContainer {
    display: flex;
    justify-content: center;
    position: relative;
}

.uploadPicture {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    margin-top: 30px;
    cursor: pointer;
    object-fit: cover;
}



.uploadIcon{
    position: absolute;
    bottom: 10px;
    color: black;
}