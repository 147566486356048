@import "../../../pages/membership/style.module.scss";

.crossIcon {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: end;
}

.radio1 {
  color: $DARKPURPLE !important;
  opacity: 0.5;
}

.smallHeading {
  height: 20px;
  font-size: 12px;
  color: black;
  display: flex;
  justify-content: start;
}

.uploadImageContainer {
  height: 190px;
  border: 1px solid $GREY;
  border-style: dotted;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $GREY;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  label {
    margin-top: 5px;
    font-family: Poppins;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
  }
}

.cardInfoContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 30px;
}

.cardView {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.uploadedImage {
  height: 190px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.planFormModalWrapper {
  // margin-top: 1rem;
  margin-top: 0;
  // margin-bottom: 1rem;
}

.title {
  color: #9a469b;
  // padding-bottom: 0.5rem;
  border-color: #9a469b;
  font-weight: 700;
}

.twoColumnsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.2rem;
  // max-width: 50%;
}

// .twoColumnsGrid:last-child {
//   align-items: start;
// }

.gridCell {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: Poppins !important;
  font-size: 14px;
}

.gridCell span {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 0.5rem;
}

.gridCell span:first-child {
  color: #868e96;
  background-color: #f9faf9;
  font-size: 13px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.lastItem {
  align-items: start;
  grid-column: 1/-1;
}

.btnContainer {
  justify-content: flex-end;

  button {
    padding: 10px 40px;
    width: max-content;
  }
}

.addNewMembershipForm {
  display: flex;
  width: 100% !important;
  margin-left: 0 !important;
  column-gap: 0.5rem;
  flex-direction: row;

  div {
    flex: 45%;
  }
}

.label {
  font-family: "poppins";
  font-size: 14px;
}

@media screen and (max-width: 500px) {
  .btnContainer {
    justify-content: center;
  }

  .addNewMembershipForm {
    flex-direction: column;
    row-gap: 0.5rem;

    div {
      flex: 100%;
    }
  }

  .twoColumnsGrid {
    grid-template-columns: 1fr;
  }

  .gridCell:last-child span:last-child {
    height: 3rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.cardDescription {
  color: var(--gray-900, $GREY);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 5px 0px;
}

@media screen and (max-width: 600px) {
  .cardInfoContainer {
    display: block;
  }
}

.editedChecked {
  background-color: $RED;
}

.checkbox {
  border: 1.5px solid $TEXTGREY;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 16px;
}

.checked {
  border: 1.5px solid $PINK;
  background-color: $PINK;
}

.selectionLabel {
  font-family: Poppins;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: $BLACK2;
}
