@import "shared/utils/colors.scss";

.card {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
}

.book {
    height: 300px;
    width: 220px;
    object-fit: fill;
}

.bookInfo {
    width: 100%;
    padding: 20px 35px;
}


.title {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.subTitle {
    color: var(--gray-gray-10, $BLACK);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
}

.contentContainer {
    display: flex;
    border: 1px solid $GREY2;
    padding: 15px;
    border-radius: 10px;
}

@media screen and (max-width:750px) {
    .contentContainer {
        display: block;
    }

    .picContainer {
        display: flex;
        justify-content: center;
    }

    .bookInfo {
        margin-top: 20px;
    }
}