@import "shared/utils/colors.scss";

.title {
  color: $DARKPURPLE;
  border-color: $DARKPURPLE;
  font-weight: 700;
  font-family: Poppins !important;
}

.crossIcon {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: end;
}

.headingContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: Poppins !important;
  font-size: 14px;
}

.headingContainer span {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 0.5rem;
}

.headingContainer span:first-child {
  color: #868e96;
  background-color: #f9faf9;
  font-size: 13px;
}
