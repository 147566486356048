@import "shared/utils/colors.scss";

.topLevelContainer {
  display: flex;
  justify-content: space-between;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 4px;
}

.heading {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: $BLACK2;
}

.toptableContainer {
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $LIGHT_BACKGROUND;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-top-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
}

.subHeading {
  font-family: "Plus Jakarta Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: $GREY3;
}

.tabLabel {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 23px !important;
  color: $BLACK !important;
  text-transform: none !important;
  cursor: pointer;
}

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.tabCounter {
  background-color: white;
  border: 1.5px solid $BLACK;
  color: $BLACK;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.active {
  .tabCounter {
    background-color: $BLACK;
    color: white !important;
  }
}

.mainContainer {
  border-radius: 16px !important;
  background-color: white !important;
  border: 1px solid $BORDERGREY !important;
  margin-top: 40px;
}

.tableContainer {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  box-shadow: none !important;

  &::-webkit-scrollbar-track {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #f7a1bd;
  }
}

.tableHeading {
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: $BLACK2;
}

.tableSubHeading {
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: $TEXTGREY2 !important;
  max-width: 550px;
  min-height: 40px;
}

.tableHead {
  background-color: $LIGHT_BACKGROUND;
  tr {
    th {
      min-width: 135px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      font-family: "Poppins";
      color: $TEXTGREY2;
      padding: 24px 16px !important;
    }
  }
}

.tableBody {
  tr {
    td {
      font-family: "Poppins" !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      padding: 24px 16px !important;
    }
  }
}

.lastCells {
  td {
    border-bottom: none !important;
  }
}

.boxContainer {
  margin-top: 10px;
}

.profilepic {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.nameCell {
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;

  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    min-width: 135px;
    color: $BLACK3;
  }
}

.emailLabel {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: $TEXTGREY !important;
}

.statusCell {
  label {
    padding: 2px 8px;
    font-family: "Inter";
    font-weight: 500;
    line-height: 18px;
    font-size: 12px;
    width: fit-content;
    border-radius: 16px;
  }
}

.pending {
  background-color: $LIGHTYELLOW2;
  color: $PENDING_YELLOW;
}

.neutral {
  background-color: $LIGHTL_BLUE;
  color: $NEUTRAL_BLUE;
}

.approved {
  background-color: $LIGHTGREEN;
  color: $GREEN;
}

.rejected {
  background-color: $LIGHTRED;
  color: $RED;
}

.actionContainer {
  display: flex;

  div {
    background-color: $TransparentGREY;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
  }
}

.icons {
  width: 19px;
  height: 19px;
}

.limitedCell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 135px;
  min-width: 135px;
  color: $BLACK3;
}

.bookImg {
  height: 40px;
  width: 37px;
  border-radius: 2px;
  object-fit: cover;
}

.searchIcon {
  position: absolute;
  top: 12px;
  left: 0;
  padding-left: 10px;
}

.input {
  color: var(--gray-gray-07, $TEXTGREY);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid var(--gray-gray-06, $TEXTGREY);
  border-radius: 32px;
  padding: 10px 14px;
  padding-left: 40px;
  width: 250px;
}

.input:focus-visible {
  outline: none;
}

.gridContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.boxContainer {
  div {
    div {
      overflow: scroll;

      &::-webkit-scrollbar-track {
        display: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
      }
    }
  }
}

.centerToCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .toptableContainer {
    display: block !important;
  }

  .input {
    width: 100%;
    margin-top: 20px;
  }

  .searchIcon {
    top: 31px;
  }
}

.borderBottom {
  border-bottom: 1px solid $GREY2 !important;
}

.createBtn {
  background-color: $DARKPURPLE;
  border-radius: 32px;
  border: none !important;
  padding: 15px 44px;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  color: white !important;
  font-family: "Poppins";
  font-weight: 500;
}

.menuBtn {
  border: none;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: $BLACK3;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    color: $PINK;
  }

  svg {
    font-size: 16px;
    border: 1px solid #dee2e6;
    border-radius: 50%;
  }
}

.menuItems {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  padding: 12px 24px !important;
  color: $BLACK3;
}

.menuIcon {
  width: 30px !important;
  height: 30px !important;
  margin-right: 10px;
  border-radius: 0% !important;
  border: none !important;
}

@media screen and (max-width: 650px) {
  .topLevelContainer {
    display: block;
  }

  .rangePicker {
    display: flex;
    margin-top: 20px;
  }
}

.deactive {
  path {
    stroke: $GREEN2 !important;
  }

  ellipse {
    stroke: $GREEN2 !important;
  }

  circle {
    stroke: $GREEN2 !important;
  }
}

.active {
  path {
    stroke: $RED2 !important;
  }

  ellipse {
    stroke: $RED2 !important;
  }

  circle {
    stroke: $RED2 !important;
  }
}

.noUserCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px;
}
