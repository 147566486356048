@import "shared/utils/colors.scss";

.title {
  color: var(--gray-900, $BLACK);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
}

.topDiv {
  height: 80vh;
  overflow-y: scroll;
  margin-top: 1.5rem;
}

.topDiv::-webkit-scrollbar {
  display: none;
}

.noDataCard {
  display: flex;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  align-items: center;
}

.checkbox {
  color: $DARKPURPLE !important;
}

.rangeInputWrapper svg {
  position: relative !important;
  padding: 0 !important;
  fill: #9a469b;
}

.filterWrapper .message {
  margin: 0;
  padding: 0;
  color: gray;
}

.filterWrapper div div {
  padding: 8px;
}

.filterWrapper svg {
  fill: white !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.filtersContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 0.2rem;
}
