@import "shared/utils/colors.scss";

.topLevelGrid {
  padding-left: 17px;
  margin-top: 0px !important;
}

.chartGrid {
  padding-right: 20px !important;
}

.cardContent {
  padding: 30px !important;
  padding-bottom: 30px !important;
}

.title {
  color: var(--gray-900, $BLACK);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  // margin-bottom: 20px;
  //   width: 100%;
}

.card {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
}

.cardContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.rangeInputWrapper input {
  border: none;
  padding: 0;
  cursor: pointer;
}

.rangeInputWrapper>div>div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.rangeInputWrapper svg {
  position: relative !important;
  padding: 0 !important;
  fill: #9a469b;
}

@media screen and (min-width: 500px) {
  .cardContentWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .rangeInputWrapper>div>div {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 900px) {
  .chartGrid {
    padding-right: 0px !important;
  }
}