@import "shared/utils/colors.scss";

.topLevelGrid {
  padding-left: 17px;
  margin-top: 0px !important;
}

.cardContent {
  padding: 30px !important;
  padding-bottom: 30px !important;
}

.title {
  color: var(--gray-900, $BLACK);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  // margin-bottom: 20px;
  // width: 100%;
}

.noDataCard {
  display: flex;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  align-items: center;
}

.cardContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.rangeInputWrapper {
  display: flex;
  align-items: center;
}

.rangeInputWrapper input {
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100px;
}

.rangeInputWrapper>div>div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.rangeInputWrapper svg {
  position: relative !important;
  padding: 0 !important;
  fill: #9a469b;
}

.noData svg {
  width: 8rem;
  height: 8rem;
}

h5 {
  margin: 0;
}

.dashboardStatsWrapper {
  margin-bottom: 2rem;
}

.centerText {
  display: flex;
  align-items: start;
  flex-direction: column;
  text-align: center;
  justify-content: start;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  gap: 1rem;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cardWrapper>div {
  padding: 1rem 1rem;
}

.chartGrid {
  display: flex;
  flex-direction: column !important;
}

.chartGrid>div {
  flex-basis: 50% !important;
}

@media screen and (min-width: 500px) {
  .gridContainer {
    grid-template-columns: minmax(350px, auto) 1fr;
  }

  .cardWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .cardWrapper>div {
    padding: 1rem 1rem;
  }

  .centerText {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .cardContentWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .rangeInputWrapper>div>div {
    gap: 0.5rem;
  }
}

.card {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
}

.chartGrid {
  padding-right: 20px !important;
}

@media screen and (max-width: 900px) {
  .chartGrid {
    padding-right: 0px !important;
  }
}